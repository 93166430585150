import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { renderRoutes } from "react-router-config";
import {
  getFirebaseAdminUser,
  getPendingNurses,
  getTotalNewChart,
} from "../store/actions";
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";

function DashboardLayout({ route }) {
  const { firebaseUserInfo } = useSelector(({ UserAuth }) => UserAuth);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getFirebaseAdminUser());
  }, []);
  useEffect(() => {
    if (firebaseUserInfo) {
      dispatch(getTotalNewChart());
    }
  }, [firebaseUserInfo]);
  useEffect(() => {
    dispatch(getPendingNurses());
  }, []);
  return (
    <div className="flex ">
      <Sidebar />
      <div className="flex-1 w-100 ">
        <main className="bg-paleblue min-h-screen">
          <Navbar />
          <div>{renderRoutes(route.routes)}</div>
        </main>
      </div>
    </div>
  );
}

export default DashboardLayout;
