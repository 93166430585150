import React from "react";
import { useSelector } from "react-redux";

function NurseReview() {
  const { selectedNurse } = useSelector(({ Nurses }) => Nurses);
  return (
    <div className="space-y-6 bg-white rounded-xl p-6 md:p-10">
      <div className="flex justify-between">
        <h5 className="text-xl font-bold text-darkblue">Reviews</h5>
        <p className="text-lightblack text-sm font-semibold">
          {selectedNurse?.nurse_reviews?.length} reviews
        </p>
      </div>
      <div className="space-y-2">
        {selectedNurse?.nurse_reviews.map((review, index) => (
          <div className="bg-paleblue p-4">
            <p className="text-sm font-semibold text-darkblue">
              Kamali Yves{" "}
              <span className="px-1 text-yellow-600 text-xs">
                - {review?.rating}
              </span>
            </p>
            <p className="text-lightblack text-sm">{review?.review}</p>
          </div>
        ))}
        {selectedNurse?.nurse_reviews?.length === 0 && (
          <p className="text-lightblack text-sm">no reviews yet</p>
        )}
      </div>
    </div>
  );
}

export default NurseReview;
