import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import userImg from "../../assets/images/nurse 4.png";
import { BsDot, BsFillStarFill, BsExclamationCircleFill } from "react-icons/bs";
import { GetValidImage } from "../../utils/image";

function NurseProfile({ rejected, blocked }) {
  const { selectedNurse } = useSelector(({ Nurses }) => Nurses);
  const { nurseId } = useParams();
  function calculateAgeInYears(date) {
    var now = new Date();
    var current_year = now.getFullYear();
    var year_diff = current_year - parseInt(date, 10);
    var birthday_this_year = new Date(
      current_year,
      parseInt(date?.substring(5, 7), 10),
      parseInt(date?.substring(8, 10), 10)
    );
    var has_had_birthday_this_year = now >= birthday_this_year;
    return has_had_birthday_this_year ? year_diff : year_diff - 1;
  }
  return nurseId ? (
    <div>
      <div className="flex space-x-3.5 sm:space-x-10 flex-wrap">
        <div className="h-40 w-40 flex items-center rounded-3xl ">
          <img
            className="object-cover h-40 w-40 rounded-md"
            src={GetValidImage(selectedNurse?.avatar)}
            alt="User"
          />
        </div>
        <div className="flex-1">
          <h5 className="text-xl font-bold text-darkblue mb-2">
            {selectedNurse?.full_names}
          </h5>
          <div className="space-y-2">
            <div className="flex">
              <BsDot className="text-lightgreen text-xl" />

              <p className="text-sm text-lightblack font-normal">
                {selectedNurse?.gender}
              </p>
            </div>
            <div className="flex">
              <BsDot className="text-lightgreen text-xl" />

              <p className="text-sm text-lightblack font-normal">
                {calculateAgeInYears(selectedNurse?.date_of_birth)} Years
              </p>
            </div>
            <div className="flex">
              <BsDot className="text-lightgreen text-xl" />

              <p className="text-sm text-lightblack font-normal">
                {selectedNurse?.nurse_profile?.patient_number} Patients
              </p>
            </div>

            <div className="flex flex-wrap justify-between">
              <div className="flex space-x-2 items-center mx-2">
                <BsFillStarFill className="text-yellow-500 text-sm" />

                <p className="text-sm text-darkblue font-bold">
                  {selectedNurse?.nurse_profile?.rating}
                </p>
              </div>
              <p
                className={
                  (selectedNurse?.status === "available"
                    ? " text-lightgreen bg-lightgreen bg-opacity-20 rounded-2xl "
                    : " text-red-500 bg-red-200 rounded-2xl ") +
                  " text-center py-1 px-3  "
                }
              >
                {selectedNurse?.status}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="space-y-2 mx-3 my-10 lg:my-16">
        <p className="text-sm text-darkblue font-bold border-t border-b py-2.5">
          Phone:{" "}
          <span className="px-3 text-sm text-lightblack font-normal">
            {selectedNurse?.phone_number}
          </span>
        </p>
        <p className="text-sm text-darkblue font-bold  border-b py-2.5">
          Email:{" "}
          <span className="px-3 text-sm text-lightblack font-normal">
            {selectedNurse?.email}
          </span>
        </p>
        <p className="text-sm text-darkblue font-bold  border-b py-2.5">
          Location:{" "}
          <span className="px-3 text-sm text-lightblack font-normal">
            {selectedNurse?.address}
          </span>
        </p>
      </div>
      <div>
        <h5 className="text-xl font-bold text-darkblue">Biography</h5>
        <p className=" my-6  rounded-2xl text-sm text-lightblack">
          {selectedNurse?.nurse_profile?.biography}
        </p>
      </div>
    </div>
  ) : (
    <div className="flex space-x-4 sm:space-x-10 flex-wrap">
      <div className="h-40 w-40  flex items-center rounded-3xl relative">
        {rejected === true ? (
          <div className="absolute -top-5 -left-5 bg-white rounded-circle">
            <BsExclamationCircleFill className="text-red-500 text-6xl" />
          </div>
        ) : null}
        {blocked === true ? (
          <div className="absolute -top-5 -left-5 bg-white rounded-circle">
            <svg
              className="w-12 h-12"
              width="72"
              height="72"
              viewBox="0 0 72 72"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_2572_216)">
                <path
                  d="M61.463 10.5329C47.4189 -3.5112 24.5752 -3.5112 10.532 10.5329C-3.50612 24.5769 -3.50612 47.4257 10.532 61.4663C24.5752 75.5104 47.4257 75.5104 61.463 61.4663C75.5079 47.4257 75.5079 24.5744 61.463 10.5329ZM16.173 16.1721C25.4029 6.93962 39.5223 5.50402 50.2749 11.8636L11.8679 50.2783C5.50065 39.5248 6.9371 25.4046 16.173 16.1721ZM55.8212 55.8254C46.6546 64.9919 32.6714 66.4673 21.9475 60.2641L60.259 21.9509C66.4715 32.6756 64.9937 46.6588 55.8212 55.8254Z"
                  fill="#FF0000"
                />
              </g>
              <defs>
                <clipPath id="clip0_2572_216">
                  <rect width="72" height="72" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </div>
        ) : null}
        <img
          className="object-cover h-40 w-40 rounded-md"
          src={GetValidImage(selectedNurse?.avatar)}
          alt="User"
        />
      </div>
      <div>
        <h5 className="text-xl font-bold text-darkblue mb-2">
          {" "}
          {selectedNurse?.full_names}
        </h5>
        <div className="space-y-2">
          <p className="text-sm text-darkblue font-bold ">
            Phone:{" "}
            <span className="text-sm text-lightblack font-normal">
              {selectedNurse?.phone_number}
            </span>
          </p>
          <p className="text-sm text-darkblue font-bold ">
            Email:{" "}
            <span className="text-sm text-lightblack font-normal">
              {selectedNurse?.email}
            </span>
          </p>
          <p className="text-sm text-darkblue font-bold  ">
            Location:{" "}
            <span className=" text-sm text-lightblack font-normal">
              {selectedNurse?.address}
            </span>
          </p>
          <p className="text-sm text-darkblue font-bold ">
            Gender:{" "}
            <span className="text-sm text-lightblack font-normal">
              {selectedNurse?.gender}
            </span>
          </p>
          <p className="text-sm text-darkblue font-bold ">
            Age:{" "}
            <span className="text-sm text-lightblack font-normal">
              28 Years
            </span>
          </p>
        </div>
      </div>
    </div>
  );
}

export default NurseProfile;
