import {
  createSubscriptionRequest,
  deleteSubscriptionRequest,
  fetchSubscriptionsRequest,
  updateSubscriptionRequest,
} from "../../api";
import AlertMessage from "../../components/alert/Alert";
import actionTypes from "./types";
export const createSubscriptionPlan = (data, alert) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.createSubscription });
    createSubscriptionRequest(data)
      .then((res) => {
        dispatch({ type: actionTypes.createSubscriptionSuccess, payload: res });
        alert.success(<AlertMessage message="Plan successfully created" />);
        dispatch(getSubscriptionsPlans());
      })
      .catch((err) => {
        if (err && err.errors) {
          dispatch({
            type: actionTypes.createSubscriptionFail,
            payload: err.errors,
          });
        } else {
          dispatch({ type: actionTypes.createSubscriptionFail, payload: err });
          alert.error(
            <AlertMessage message="Something went wrong, try again later" />
          );
        }
      });
  };
};

export const getSubscriptionsPlans = () => {
  return (dispatch) => {
    dispatch({ type: actionTypes.getSubscriptions });
    fetchSubscriptionsRequest()
      .then((res) => {
        if (res && res.data) {
          dispatch({
            type: actionTypes.getSubscriptionsSuccess,
            payload: res?.data,
          });
        }
      })
      .catch((err) => {
        dispatch({ type: actionTypes.getSubscriptionsFail });
      });
  };
};
export const updateSubscriptionPlan = (data, plan, alert, handleClose) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.updateSubscription });
    updateSubscriptionRequest(plan?.id, data)
      .then((res) => {
        dispatch({ type: actionTypes.updateSubscriptionSuccess, payload: res });
        handleClose();
        alert.success(<AlertMessage message="Plan successfully updated" />);
        dispatch(getSubscriptionsPlans());
      })
      .catch((err) => {
        if (err && err.errors) {
          dispatch({
            type: actionTypes.updateSubscriptionFail,
            payload: err.errors,
          });
        } else {
          dispatch({ type: actionTypes.updateSubscriptionFail, payload: err });
          alert.error(
            <AlertMessage message="Something went wrong, try again later" />
          );
        }
      });
  };
};
export const deleteSubscriptionPlan = (plan, alert, handleClose) => {
  return (dispatch, getState) => {
    const plans = getState().Subscriptions.subscriptions.slice();
    dispatch({ type: actionTypes.deleteSubscription });
    deleteSubscriptionRequest(plan?.id)
      .then((res) => {
        var data = [...plans];
        data.splice(
          data.findIndex((c) => c.id === plan.id),
          1
        );
        dispatch({
          type: actionTypes.deleteSubscriptionSuccess,
          payload: data,
        });
        handleClose();
        alert.success(<AlertMessage message="Plan successfully deleted" />);
      })
      .catch((err) => {
        dispatch({ type: actionTypes.deleteSubscriptionFail, payload: err });
        handleClose();
        alert.error(
          <AlertMessage message="Something went wrong, try again later" />
        );
      });
  };
};
export const onSetSelectedSubscription = (subscription) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.setSelectedSubscriptions,
      payload: subscription,
    });
  };
};
