import React from "react";
import Moment from "react-moment";
import userImg from "../../../assets/images/Care connect logo2.png";
function SentConversation({ chart }) {
  const getTime = () => {
    let dateObj = new Date(chart?.createdAt?.seconds);
    return dateObj;
  };
  return (
    <div className="grid grid-cols-3 ">
      <div></div>
      <div className=" col-span-2 flex justify-end flex-wrap-reverse">
        <img
          src={userImg}
          alt="profile"
          className="w-16 h-16 order-2 ml-2 rounded-circle border-lightgreen border-1"
        />
        <div>
          <div className="flex flex-col text-gray-500">
            {chart?.type === "imageAttachment" ? (
              <div>
                <img
                  src={chart?.media}
                  alt="attached image"
                  className="object-cover h-40 w-40"
                />
              </div>
            ) : (
              <p className="p-3 rounded-tl-3xl rounded-br-3xl bg-lightgreen text-white text-sm w-48 md:w-64">
                {chart?.text}
              </p>
            )}

            <Moment
              className="text-xs justify-self-start"
              format="LLLL"
              fromNow
            >
              {chart.createdAt.toDate()}
            </Moment>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SentConversation;
