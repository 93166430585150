import React, { useState } from "react";
import { useAlert } from "react-alert";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { overrideNurseStatus } from "../../store/actions";

function ChangeNurseStatus({ handleClose }) {
  const { selectedNurse, isOverriddingNurseStatus } = useSelector(
    ({ Nurses }) => Nurses
  );
  const [status, setStatus] = useState(selectedNurse?.status);
  const dispatch = useDispatch();
  const alert = useAlert();
  return (
    <div>
      <Modal.Header closeButton>
        <Modal.Title>
          <div className="text-base font-semibold text-darkblue">
            Change Nurse {selectedNurse?.full_names}'s availability status
          </div>{" "}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-sm px-3">
        Do you really want to change this nurses's availability? If so, choose
        their new availability below
        <div className="p-4 lg:min-w-full sm:w-60">
          <div className="form-check mx-2">
            <input
              className="form-check-input"
              type="radio"
              name="status"
              id="available"
              value="available"
              selected={status === "available" ? true : false}
              required
              onChange={(e) => {
                setStatus(e.target.value);
              }}
            />
            <label
              className="form-check-label text-sm text-darkblue"
              for="available"
            >
              Available
            </label>
          </div>
          <div className="form-check mx-2">
            <input
              className="form-check-input"
              type="radio"
              name="status"
              id="busy"
              value="busy"
              selected={status === "busy" ? true : false}
              required
              onChange={(e) => {
                setStatus(e.target.value);
              }}
            />
            <label
              className="form-check-label text-xs text-darkblue"
              for="busy"
            >
              Busy
            </label>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="flex justify-between">
        <button
          className="bg-red-500 rounded px-3 py-1 text-white text-sm hover:bg-red-400 cursor-pointer"
          onClick={handleClose}
        >
          No, Cancel
        </button>
        <button
          className="bg-gray-500 rounded px-3 py-1 text-white text-sm hover:bg-gray-400 cursor-pointer"
          onClick={() => {
            var data = { nurse_id: selectedNurse?.id, status: status };
            dispatch(overrideNurseStatus(data, alert, handleClose));
          }}
        >
          {isOverriddingNurseStatus ? "Changing ..." : "Yes, Change"}
        </button>
      </Modal.Footer>
    </div>
  );
}

export default ChangeNurseStatus;
