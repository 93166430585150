import {
  approveNurseRequest,
  blockNurseRequest,
  deleteNurseRequest,
  disapproveNurseRequest,
  fetchBlockedNursesRequest,
  fetchNursesRequest,
  fetchPendingNursesRequest,
  fetchRejectedNursesRequest,
  overrideNurseStatusRequest,
} from "../../api";
import actionTypes from "./types";
import axios from "axios";
import AlertMessage from "../../components/alert/Alert";
import html2pdf from "html2pdf.js";
export const getNurses = (
  searchTxt,
  rating,
  location,
  gender,
  from,
  to,
  status
) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.getNurses });

    fetchNursesRequest(
      searchTxt ?? null,
      rating ?? null,
      location ?? null,
      gender ?? null,
      from ?? null,
      to ?? null,
      status ?? null
    )
      .then((res) => {
        if (res && res.data) {
          dispatch({ type: actionTypes.getNursesSuccess, payload: res.data });
        }
      })
      .catch((err) =>
        dispatch({ type: actionTypes.getNursesFail, payload: err })
      );
  };
};

export const getPendingNurses = (searchText) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.getPendingNurses });

    fetchPendingNursesRequest(searchText ?? null)
      .then((res) => {
        if (res && res.data) {
          dispatch({
            type: actionTypes.getPendingNursesSuccess,
            payload: res.data,
          });
          dispatch(
            onSetSelectedNurse(
              res?.data && res?.data && res?.data?.length > 0 && res?.data[0]
            )
          );
        }
      })
      .catch((err) =>
        dispatch({ type: actionTypes.getPendingNursesFail, payload: err })
      );
  };
};
export const getRejectedNurses = (searchText) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.getRejectedNurses });

    fetchRejectedNursesRequest(searchText ?? null)
      .then((res) => {
        if (res && res.data) {
          dispatch({
            type: actionTypes.getRejectedNursesSuccess,
            payload: res.data,
          });
          dispatch(
            onSetSelectedNurse(
              res?.data && res?.data && res?.data?.length > 0 && res?.data[0]
            )
          );
        }
      })
      .catch((err) =>
        dispatch({ type: actionTypes.getRejectedNursesFail, payload: err })
      );
  };
};
export const getBlockedNurses = (searchText) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.getBlockedNurses });

    fetchBlockedNursesRequest(searchText ?? null)
      .then((res) => {
        if (res && res.data) {
          dispatch({
            type: actionTypes.getBlockedNursesSuccess,
            payload: res.data,
          });
          dispatch(
            onSetSelectedNurse(
              res?.data && res?.data && res?.data?.length > 0 && res?.data[0]
            )
          );
        }
      })
      .catch((err) =>
        dispatch({ type: actionTypes.getBlockedNursesFail, payload: err })
      );
  };
};
export const approveNurse = (nurse, alert, history) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.approveNurse });
    approveNurseRequest(nurse?.id)
      .then((res) => {
        dispatch({
          type: actionTypes.approveNurseSuccess,
          payload: "Nurse successfully approved",
        });
        history.push("/dashboard/nurses");
        dispatch(getPendingNurses());
        alert.success(<AlertMessage message="Nurse successfully approved" />);
      })
      .catch((err) => {
        dispatch({ type: actionTypes.approveNurseFail, payload: err });
        alert.error(
          <AlertMessage message="Something went wrong, please try again !" />
        );
      });
  };
};
export const disapproveNurse = (
  nurse,
  alert,
  history,
  setShowRejectedNurseAlert,
  decline
) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.disapproveNurse });
    disapproveNurseRequest(nurse?.id)
      .then((res) => {
        dispatch({
          type: actionTypes.disapproveNurseSuccess,
          payload: "Nurse successfully rejected",
        });
        if (decline?.decline === true) {
          // alert.success(<AlertMessage message="Nurse successfully declined" />);
          setShowRejectedNurseAlert(true);
        } else {
          setShowRejectedNurseAlert(true);
          // history.push("/dashboard/nurses");
          // alert.success(<AlertMessage message="Nurse successfully disabled" />);
        }
      })
      .catch((err) => {
        dispatch({ type: actionTypes.disapproveNurseFail, payload: err });
        alert.error(
          <AlertMessage message="Something went wrong, please try again !" />
        );
      });
  };
};
export const blockNurse = (
  nurse,
  alert,
  history,
  setShowRejectedNurseAlert
) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.blockNurse });
    blockNurseRequest(nurse?.id)
      .then((res) => {
        dispatch({
          type: actionTypes.blockNurseSuccess,
          payload: "Nurse successfully blocked",
        });
        setShowRejectedNurseAlert(true);

        // history.push("/dashboard/nurses");
        // alert.success(<AlertMessage message="Nurse successfully disabled" />);
      })
      .catch((err) => {
        dispatch({ type: actionTypes.blockNurseFail, payload: err });
        alert.error(
          <AlertMessage message="Something went wrong, please try again !" />
        );
      });
  };
};
export const overrideNurseStatus = (data, alert, handleClose) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.overrideNurseStatus });
    overrideNurseStatusRequest(data)
      .then((res) => {
        dispatch({
          type: actionTypes.overrideNurseStatusSuccess,
          payload: "Nurse status successfully changed",
        });
        handleClose();
        alert.success(
          <AlertMessage message="Nurse status successfully changed" />
        );
        dispatch(getNurses());
      })
      .catch((err) => {
        dispatch({ type: actionTypes.overrideNurseStatusFail, payload: err });
        handleClose();
        alert.error(
          <AlertMessage message="Something went wrong, please try again !" />
        );
      });
  };
};
export const deleteNurse = (nurse, history, alert) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.deleteNurse });
    deleteNurseRequest(nurse?.id)
      .then((res) => {
        dispatch({
          type: actionTypes.deleteNurseSuccess,
          payload: "Nurse successfully deleted",
        });
        history.push("/dashboard/nurses");
        alert.success(<AlertMessage message="Nurse successfully deleted" />);
      })
      .catch((err) => {
        dispatch({ type: actionTypes.deleteNurseFail, payload: err });

        alert.error(
          <AlertMessage message="Something went wrong, please try again !" />
        );
      });
  };
};
export const onSetSelectedNurse = (nurse) => {
  return (dispatch) => {
    if (nurse) {
      dispatch({ type: actionTypes.setSelectedNurse, payload: nurse });
    } else {
      dispatch({ type: actionTypes.setSelectedNurse, payload: null });
    }
  };
};
export const generatePdfNurses = () => {
  return (dispatch) => {
    var element = document.getElementById("nurse-to-print");
    var clonedElement = element.cloneNode(true);
    // change display of cloned element

    clonedElement.style.display = "block";
    var opt = {
      margin: 1,
      filename: "Nurses.pdf",
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "pt", format: "a4", orientation: "portrait" },
    };

    html2pdf().from(clonedElement).set(opt).save();
    clonedElement.remove();
  };
};
