import { getUserRequest, loginRequest, updateUserRequest } from "../../api";
import actionTypes from "./types";
import { removeUserStorage, setUserStorage } from "../../utils/userUtils";
import { axiosInstance } from "../../api/config";
import AlertMessage from "../../components/alert/Alert";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";
import {
  setDoc,
  doc,
  Timestamp,
  updateDoc,
  collection,
  onSnapshot,
  query,
  where,
  getDocs,
} from "firebase/firestore";
import { auth, db, storage } from "../../firebase";
import { async } from "@firebase/util";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { getTotalNewChart } from "./charts.actions";
export const loginUser = (data, history) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.LogUserIn });
    loginRequest(data)
      .then((res) => {
        if (res?.data?.role) {
          if (res?.data?.role?.length && res?.data?.role[0]?.name === "Admin") {
            setUserStorage(res?.data);
            axiosInstance.defaults.headers.common = {
              Authorization: `Bearer ${res?.data.token}`,
            };
            dispatch({
              type: actionTypes.LogUserInSuccess,
              payload: res?.data?.user,
            });
            dispatch(getFirebaseAdminUser());
          } else {
            var error = { message: "Invalid email/password. Try again. " };
            dispatch({ type: actionTypes.LogUserInFail, payload: error });
          }
        }
      })
      .catch((err) => {
        if (err?.errors) {
          dispatch({ type: actionTypes.LogUserInFail, payload: err.errors });
        } else {
          dispatch({ type: actionTypes.LogUserInFail, payload: err });
        }
      });
  };
};

export const getFirebaseAdminUser = () => {
  return async (dispatch) => {
    dispatch({ type: actionTypes.getFirebaseUser });
    const usersRef = collection(db, "users");
    const usersReff = collection(db, "users");
    const qt = query(usersReff, where("role", "==", "Admin"));
    const querySnapshott = await getDocs(qt);

    if (querySnapshott.size === 0) {
      console.log("admin user not found:", querySnapshott);
    } else {
      console.log("admin user:", querySnapshott);
    }
    // const q = query(usersRef, where("uid", "not-in", [userInfo?.id]));
    const unsubz = onSnapshot(usersRef, (querySnapshot) => {
      let users = [];
      querySnapshot.forEach((doc) => {
        users.push(doc.data());
      });
      if (users?.length > 0) {
        users.map((user, index) => {
          if (user?.role === "Admin") {
            dispatch({
              type: actionTypes.getFirebaseUserSuccess,
              payload: user,
            });
            // dispatch(getTotalNewChart());
          }
        });
      } else {
        dispatch({ type: actionTypes.getFirebaseUserFail });
      }
    });
  };
};
export function logoutFirebase() {
  return async (dispatch, getState) => {
    const userInfo = getState().UserAuth.firebaseUserInfo;
    try {
      await updateDoc(doc(db, "users", userInfo.uid), {
        isOnline: false,
      });
      // await signOut(auth);
    } catch {
      alert("Error!");
    }
  };
}
export const getUser = () => {
  return (dispatch) => {
    dispatch({ type: actionTypes.getUser });
    getUserRequest()
      .then((res) => {
        dispatch({ type: actionTypes.getUserSuccess, payload: res });
      })
      .catch((err) => {
        dispatch({ type: actionTypes.getUserFail, payload: err });
      });
  };
};
export const updateUser = (data, setEditProfile, activePane, alert) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.updateUser });
    updateUserRequest(data)
      .then((res) => {
        if (res && res.data) {
          if (activePane === "account-details") {
            const user = JSON.parse(localStorage.getItem("user"));
            var userInfo = { token: user?.token, user: res?.data[0] };

            setUserStorage(userInfo);
            dispatch({
              type: actionTypes.updateUserSuccess,
              payload: res?.data[0],
            });
            setEditProfile(false);
            alert.success(
              <AlertMessage message="Profile successfully updated !" />
            );
          } else {
            dispatch({
              type: actionTypes.updateUserSuccess,
              payload: res?.data[0],
            });
            setEditProfile(false);
            alert.success(
              <AlertMessage message="Password successfully updated !" />
            );
          }
        }
      })
      .catch((err) => {
        dispatch({ type: actionTypes.updateUserFail, payload: err });
      });
  };
};
export const onUserLogout = () => {
  return (dispatch, getState) => {
    const userInfo = getState().UserAuth.firebaseUserInfo;
    if (userInfo) {
      dispatch(logoutFirebase());
    }
    dispatch({ type: actionTypes.LogUserOut });
    removeUserStorage();
  };
};
