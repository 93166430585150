import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ImSearch } from "react-icons/im";
import { FcCalendar } from "react-icons/fc";

import { getReport, onSetSelectedReport } from "../../store/actions";
import ReportDocument from "./ReportDocument";
import userImg from "../../assets/images/nurse 2.png";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Shimmers from "../loaders/Shimmers";
import { RiCalendar2Fill } from "react-icons/ri";
import { GetValidImage } from "../../utils/image";
import moment from "moment";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
function ReportsList() {
  const { reports, isFetchingReport, selectedReport, newReportsSum } =
    useSelector(({ Reports }) => Reports);

  const [startDate, setStartDate] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState(null);
  const [nameSearchText, setNameSearchText] = useState("no text");
  const [datePickerIsOpen, setDatePickerIsOpen] = useState(false);
  const [activeReport, setActiveReport] = useState(null);

  const onSetActiveReport = (index) => {
    setActiveReport(reports[index]);
    if (activeReport !== null && reports[index] === activeReport) {
      setActiveReport(null);
    }
  };
  const openDatePicker = () => {
    setDatePickerIsOpen(!datePickerIsOpen);
  };
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getReport());
  }, []);
  useEffect(() => {
    if (!isFetchingReport) {
      // setNameSearchText("");
      setSelectedDate(null);
    }
  }, [isFetchingReport]);
  useEffect(() => {
    if (nameSearchText) {
      if (nameSearchText !== "no text") {
        dispatch(getReport(nameSearchText));
      }
    } else {
      dispatch(getReport());
    }
    if (selectedDate) {
      var searchtxt = "";
      dispatch(getReport(searchtxt, moment(selectedDate).format("YYYY-MM-DD")));
    }
  }, [nameSearchText, selectedDate]);
  return (
    <div className=" py-16 px-3.5 md:px-10 h-96 lg:h-screen lg:sticky lg:top-0  overflow-auto">
      {isFetchingReport ? (
        <div>
          <Shimmers height="30px" width="100%" />
        </div>
      ) : (
        <div className="flex space-x-3 items-center">
          <h2 className="text-xl leading-10 text-darkblue font-bold  my-3">
            Reports
          </h2>
          <p className=" bg-lightgreen px-4 text-white text-sm rounded-xl py-2">
            {/* {reports?.length} {reports?.length === 1 ? "Report" : "Reports"} */}
            {newReportsSum === 0
              ? "No new reports"
              : newReportsSum === 1
              ? newReportsSum + "Report"
              : newReportsSum
              ? newReportsSum + "Reports"
              : "No new reports"}
          </p>
        </div>
      )}

      <div className="flex justify-between items-center bg-white rounded-md py-3 px-3 space-x-3 mb-2 ">
        <div className="flex items-center">
          <ImSearch
            className="text-darkblue"
            role="button"
            onClick={() => dispatch(getReport(nameSearchText))}
          />
          <input
            type="text"
            id="search_text"
            name="search_text"
            onChange={(e) => setNameSearchText(e.target.value)}
            placeholder="Search chat"
            className="text-sm text-darkblue flex focus:outline-none outline-0appearance-none bg-transparent border-none w-11/12 sm:w-auto"
          />
        </div>
        <div>
          <RiCalendar2Fill
            role="button"
            onClick={() => setDatePickerIsOpen(true)}
            className="text-lightgreen text-xl"
          />
          {datePickerIsOpen ? (
            <DatePicker
              selected={startDate}
              onChange={(date) => {
                setStartDate(date);
                setSelectedDate(date);
                openDatePicker();
              }}
              onClickOutside={openDatePicker}
              open={datePickerIsOpen}
              shouldCloseOnSelect={true}
              placeholderText="Date"
              className="p-2 bg-lime-50 w-70 rounded-3xl 	text-sm	text-text-darkblue focus:outline-none"
            />
          ) : null}
        </div>
      </div>

      {isFetchingReport ? (
        <div className="space-y-3">
          <Shimmers width="100%" height="80px" />
          <Shimmers width="100%" height="80px" />
          <Shimmers width="100%" height="80px" />
        </div>
      ) : (
        <div>
          <ul className="space-y-3">
            {reports.map((report, index) =>
              report?.created_at === activeReport?.created_at ? (
                <li key={index}>
                  <div className="onHoverevnts rounded-br-3xl rounded-l-3xl border-b-4 border-lightgreen border-r bg-blue-100 flex-wrap">
                    <div className="p-3 flex flex-row space-x-3  rounded-2  items-center		">
                      <div className="relative  flex   w-10 h-10">
                        <img
                          src={GetValidImage(report?.nurse?.avatar)}
                          alt="Profile"
                          className=""
                        />
                      </div>
                      <div className="flex-2">
                        <p className="text-sm text-darkblue font-bold">
                          {report?.nurse?.full_names}
                        </p>
                        <span className="text-xs text-gray-700">
                          {moment(report?.created_at).format("l")}
                        </span>
                      </div>
                      <div className="flex-1 align-self-end flex justify-end lg:justify-start px-3 ">
                        <button className="text-sm text-lightgreen hover:opacity-70 text-end flex items-center space-x-3">
                          <p>{moment(report?.created_at).format("LT")}</p>
                        </button>
                      </div>
                    </div>
                    <div
                      className="flex  space-x-10 p-3"
                      // style={{border: '1px solid red'}}
                    >
                      <div className="flex-none ">
                        <svg
                          width="35"
                          height="35"
                          className="w-4"
                          viewBox="0 0 13 17"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M2.96065 3.44145H3.07115V2.29102H1.33662C1.00905 2.29102 0.744629 2.55544 0.744629 2.88301V16.408C0.744629 16.7356 1.00905 17 1.33662 17H11.6629C11.9905 17 12.2549 16.7356 12.2549 16.408V2.88301C12.2549 2.55544 11.9905 2.29102 11.6629 2.29102H9.93035V3.44145H10.0409C10.2579 3.44145 10.4355 3.61905 10.4355 3.83611V9.13837H9.33441L8.36157 6.45863C8.27277 6.21197 7.92942 6.19618 7.81694 6.43298L6.11398 10.0165L5.00893 8.04122C4.89648 7.83797 4.60441 7.83994 4.49195 8.04122L3.8822 9.14035H2.56798V3.83611C2.56599 3.61707 2.74358 3.44145 2.96065 3.44145ZM4.26895 10.0935C4.3755 10.0935 4.47614 10.0343 4.52747 9.94151L4.7524 9.53698L5.90284 11.5951C6.01926 11.8043 6.32513 11.7944 6.42774 11.5774L8.00046 8.26617L8.59245 9.89808C8.63586 10.0145 8.74637 10.0934 8.87069 10.0934H10.4335V14.113C10.4335 14.3301 10.2559 14.5077 10.0389 14.5077H2.96065C2.74358 14.5077 2.56599 14.3301 2.56599 14.113V10.0935H4.26895Z"
                            fill="#8E9AAD"
                          />
                          <path
                            d="M3.78932 1.91608C3.77354 1.97133 3.76367 2.02855 3.76367 2.08973V2.10354V2.291V3.31515C3.76367 3.64272 4.02809 3.90714 4.35566 3.90714H8.64759C8.97516 3.90714 9.23958 3.64272 9.23958 3.31515V2.291V2.10354V2.08973C9.23958 2.02855 9.22972 1.97133 9.21393 1.91608C9.13895 1.67336 8.91399 1.49774 8.64759 1.49774H7.89379C7.89579 1.46419 7.89774 1.43262 7.89774 1.39907C7.89774 1.31422 7.88985 1.23331 7.87603 1.15241C7.85431 1.03204 7.81683 0.915612 7.7675 0.807081C7.54255 0.329542 7.06106 0 6.50064 0C5.94022 0 5.45874 0.329542 5.23576 0.805107C5.18445 0.913639 5.14696 1.03006 5.12722 1.15044C5.11343 1.23134 5.10552 1.31225 5.10552 1.3971C5.10552 1.43064 5.10749 1.46222 5.10946 1.49576H4.35566C4.08729 1.49576 3.86234 1.67336 3.78932 1.91608ZM5.9126 1.3971C5.9126 1.3083 5.93233 1.22542 5.96785 1.15044C6.0606 0.949158 6.26385 0.809054 6.50064 0.809054C6.73744 0.809054 6.94069 0.949158 7.03343 1.15044C7.06895 1.22542 7.08869 1.3083 7.08869 1.3971C7.08869 1.43064 7.08474 1.46419 7.08079 1.49576C7.04922 1.67731 6.93674 1.83122 6.77888 1.9141C6.696 1.95949 6.60128 1.98317 6.50064 1.98317C6.39998 1.98317 6.30528 1.95752 6.22239 1.9141C6.06652 1.82925 5.95206 1.67731 5.92049 1.49576C5.91654 1.46419 5.9126 1.43064 5.9126 1.3971Z"
                            fill="#8E9AAD"
                          />
                        </svg>
                      </div>

                      {/* <p
                        className="flex-1 text-sm"
                        // style={{ fontSize: "12px", color: "#8694AF" }}
                      >
                        {report?.description?.length > 60
                          ? "" + report?.description.substring(0, 60) + " ..."
                          : report?.description}
                      </p> */}
                      <div className="read-report read-report-summary">
                        <ReactQuill
                          theme="snow"
                          value={
                            report?.description?.length > 120
                              ? "" +
                                report?.description.substring(0, 120) +
                                " ..."
                              : report?.description
                          }
                          readOnly
                        />
                      </div>
                    </div>
                    <div className=" flex justify-center items-center px-3 py-2">
                      <button
                        onClick={() => dispatch(onSetSelectedReport(report))}
                        className="text-sm text-lightgreen hover:opacity-70 text-end flex items-center space-x-3"
                      >
                        <p>Read Report</p>
                        <svg
                          width="10"
                          height="10"
                          viewBox="0 0 10 10"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M4 3.00011L1.36569 0.365801C0.861714 -0.138171 0 0.218763 0 0.931486V9.06874C0 9.78147 0.861714 10.1384 1.36569 9.63443L4 7.00012V9.06874C4 9.78147 4.86171 10.1384 5.36569 9.63443L9.43431 5.5658C9.74673 5.25338 9.74673 4.74685 9.43431 4.43443L5.36569 0.365801C4.86171 -0.138171 4 0.218763 4 0.931486V3.00011Z"
                            fill="#34BEB9"
                          />
                        </svg>
                      </button>
                    </div>
                  </div>
                </li>
              ) : (
                <li key={index}>
                  <div
                    className="p-3 flex flex-row space-x-3 bg-white rounded-br-2xl rounded-l-2xl justify-center items-center	flex-wrap	"
                    // style={{ border: "1px solid red" }}
                  >
                    <div className="relative  flex   w-10 h-10">
                      <img
                        src={GetValidImage(report?.nurse?.avatar)}
                        alt="Profile"
                        className=""
                      />
                    </div>
                    <div className="flex-2">
                      <p className="text-sm text-darkblue font-bold">
                        {report?.nurse?.full_names}
                      </p>
                      <span className="text-xs text-gray-700">
                        {moment(report?.created_at).format(
                          "MMMM Do YYYY, h:mm:ss a"
                        )}
                      </span>
                    </div>
                    <div className="flex-1  align-self-end flex justify-end lg:justify-start px-3">
                      <button
                        // onClick={() => dispatch(onSetSelectedReport(report))}
                        onClick={() => onSetActiveReport(index)}
                        className="text-sm text-lightgreen hover:opacity-70 text-end flex items-center space-x-3"
                      >
                        <p>View Report</p>
                        <svg
                          width="9"
                          height="10"
                          viewBox="0 0 9 10"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M6.37967 4.17163L8.55903 1.47442C8.98176 0.951241 8.60939 0.171631 7.93677 0.171631L1.59054 0.171631C0.917925 0.171631 0.545558 0.951243 0.968287 1.47442L3.14765 4.17163H1.59054C0.917925 4.17163 0.545558 4.95124 0.968287 5.47442L4.1414 9.40152C4.4616 9.7978 5.06572 9.7978 5.38592 9.40152L8.55903 5.47442C8.98176 4.95124 8.60939 4.17163 7.93677 4.17163H6.37967Z"
                            fill="#34BEB9"
                          />
                        </svg>
                      </button>
                    </div>
                  </div>
                </li>
              )
            )}
          </ul>
          {reports?.length === 0 && (
            <p className="my-4 text-sm font-bold text-gray-800">
              no reports found.
            </p>
          )}
        </div>
      )}
    </div>
  );
}

export default ReportsList;
