import React from "react";
import { useAlert } from "react-alert";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { deleteSubscriptionPlan } from "../../store/actions";

function DeletePlan({ selectedPlan, handleClose }) {
  const { isDeletingSubscription } = useSelector(
    ({ Subscriptions }) => Subscriptions
  );
  const dispatch = useDispatch();
  const alert = useAlert();
  return (
    <div>
      <Modal.Header closeButton>
        <Modal.Title>
          <div className="text-base font-semibold ">Delete Plan</div>{" "}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-sm px-3">
        Do you really want to remove this plan completely?
      </Modal.Body>
      <Modal.Footer className="flex justify-between">
        <button
          className="bg-red-500 rounded px-3 py-1 text-white text-sm hover:bg-red-400 cursor-pointer"
          onClick={handleClose}
        >
          No, Cancel
        </button>
        <button
          className="bg-gray-500 rounded px-3 py-1 text-white text-sm hover:bg-gray-400 cursor-pointer"
          onClick={() =>
            dispatch(deleteSubscriptionPlan(selectedPlan, alert, handleClose))
          }
        >
          {isDeletingSubscription ? "Deleting ..." : "Yes, Delete"}
        </button>
      </Modal.Footer>
    </div>
  );
}

export default DeletePlan;
