import actionTypes from "../actions/types";

const initialState = {
  subscriptions: [],
  isCreatingSubscription: false,
  isFetchingSubscriptions: false,
  isUpdatingSubscription: false,
  isDeletingSubscription: false,
  selectedSubscription: null,
  errors: null,
  updatingErrors: null,
  message: null,
};
export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.createSubscription:
      return {
        ...state,
        errors: null,
        isCreatingSubscription: true,
      };
    case actionTypes.createSubscriptionSuccess:
      return {
        ...state,
        message: action.payload,
        isCreatingSubscription: false,
      };
    case actionTypes.createSubscriptionFail:
      return {
        ...state,
        errors: action.payload,
        isCreatingSubscription: false,
      };
    case actionTypes.getSubscriptions:
      return {
        ...state,
        errors: null,
        isFetchingSubscriptions: true,
      };
    case actionTypes.getSubscriptionsSuccess:
      return {
        ...state,
        subscriptions: action.payload,
        isFetchingSubscriptions: false,
      };
    case actionTypes.getSubscriptionsFail:
      return {
        ...state,
        errors: action.payload,
        isFetchingSubscriptions: false,
      };
    case actionTypes.updateSubscription:
      return {
        ...state,
        updatingErrors: null,
        isUpdatingSubscription: true,
      };
    case actionTypes.updateSubscriptionSuccess:
      return {
        ...state,
        message: action.payload,
        isUpdatingSubscription: false,
      };
    case actionTypes.updateSubscriptionFail:
      return {
        ...state,
        updatingErrors: action.payload,
        isUpdatingSubscription: false,
      };
    case actionTypes.deleteSubscription:
      return {
        ...state,
        errors: null,
        isDeletingSubscription: true,
      };
    case actionTypes.deleteSubscriptionSuccess:
      return {
        ...state,
        subscriptions: action.payload,
        isDeletingSubscription: false,
      };
    case actionTypes.deleteSubscriptionFail:
      return {
        ...state,
        errors: action.payload,
        isDeletingSubscription: false,
      };
    case actionTypes.setSelectedSubscriptions:
      return {
        ...state,
        selectedSubscription: action.payload,
      };
    default:
      return state;
  }
};
