// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore, collection } from "firebase/firestore";
import { getStorage } from "firebase/storage";

// app's Firebase configuration

// const firebaseConfig = {
//   apiKey: "AIzaSyBcACJFyViylH1oJyLLczmQn2mw1GsOvxI",
//   authDomain: "care-connect-chat.firebaseapp.com",
//   projectId: "care-connect-chat",
//   storageBucket: "care-connect-chat.appspot.com",
//   messagingSenderId: "838253543699",
//   appId: "1:838253543699:web:4b7e681a90234f8a64dae6",
//   measurementId: "G-WNG42EKDT7",
// };
const firebaseConfig = {
  apiKey: "AIzaSyByX1XgPvbbd9rndsjnP5ATjJpoLMRwFIw",
  authDomain: "careconnect-46865.firebaseapp.com",
  projectId: "careconnect-46865",
  storageBucket: "careconnect-46865.appspot.com",
  messagingSenderId: "215528139404",
  appId: "1:215528139404:web:960ce069f397c5d45de9a9",
  measurementId: "G-YSZG880S2V",
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const colRef = collection(db, "chatRoom");
const storage = getStorage(app);

export { auth, db, storage, colRef };
