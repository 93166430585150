import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  approveNurse,
  disapproveNurse,
  getRejectedNurses,
} from "../../store/actions";
import AlertMessage from "../alert/Alert";
import Shimmers from "../loaders/Shimmers";
import NurseDoc from "../nurse/NurseDoc";
import NurseProfile from "../nurse/NurseProfile";
import PendingNursesList from "./nurses/PendingNursesList";
import RejectedNurseAlert from "./nurses/RejectedNurseAlert";
function PendingRejections() {
  const {
    rejectedNurses,
    isFetchingRejectedNurses,
    selectedNurse,
    isApprovingNurse,
    isDisapprovingNurse,
    message,
  } = useSelector(({ Nurses }) => Nurses);
  const [showRejectedNurseAlert, setShowRejectedNurseAlert] = useState(false);
  const alert = useAlert();
  const history = useHistory();
  const dispatch = useDispatch();
  const handleClose = () => {
    setShowRejectedNurseAlert(false);
    alert.success(<AlertMessage message="Nurse successfully declined" />);
    dispatch(getRejectedNurses());
  };

  return (
    <div className="grid grid-cols-1 lg:grid-cols-3 lg:gap-x-4 gap-y-4 min-h-screen">
      <div>
        <PendingNursesList nurses={rejectedNurses} rejection={true} />
      </div>
      {isFetchingRejectedNurses ? (
        <div className="col-span-2 p-4 bg-white rounded-xl space-y-6">
          {/* <div className="rounded-xl overflow-hidden">
            <Shimmers width="100%" height="100px" />
          </div> */}
          <div className="rounded-xl overflow-hidden">
            <Shimmers width="100%" height="70vh" />
          </div>
        </div>
      ) : selectedNurse ? (
        <div className="col-span-2 p-8 bg-white rounded-xl space-y-6 lg:min-h-carditem">
          <NurseProfile rejected={true} />
          <div>
            <h5 className="text-xl font-bold text-darkblue my-6">Biography</h5>
            <p className="bg-paleblue p-4 rounded-2xl text-sm text-gray-500">
              {selectedNurse?.nurse_profile?.biography}
            </p>
          </div>
          <NurseDoc />
          <div className="flex flex-wrap  justify-center sm:space-x-4 my-4">
            <button
              onClick={() =>
                dispatch(approveNurse(selectedNurse, alert, history))
              }
              className="bg-lightgreen hover:opacity-70 px-10 py-1.5 text-white text-sm rounded-xl m-1"
            >
              {isApprovingNurse ? "Approving ..." : "Approve Account"}
            </button>
            <button
              // const decline=true
              onClick={() => {
                dispatch(
                  disapproveNurse(
                    selectedNurse,
                    alert,
                    history,
                    setShowRejectedNurseAlert,
                    {
                      decline: true,
                    }
                  )
                );
              }}
              className="bg-lightgreen hover:opacity-70 px-10 py-1.5 text-white text-sm rounded-xl m-1"
            >
              {isDisapprovingNurse ? "Declining ..." : "Decline Account"}
            </button>
            {/* <button className="bg-lightgreen hover:opacity-70 py-1 px-1.5 text-white text-sm rounded-xl">
      <AiTwotoneMessage />
    </button> */}
          </div>
        </div>
      ) : null}
      <Modal show={showRejectedNurseAlert} onHide={handleClose}>
        <RejectedNurseAlert
          handleClose={handleClose}
          alert={alert}
          selectedNurse={selectedNurse}
        />
      </Modal>
    </div>
  );
}

export default PendingRejections;
