import React from "react";
import Moment from "react-moment";
import { useSelector } from "react-redux";
import userImg from "../../../assets/images/user_img.png";
function ReceivedConversation({ chart }) {
  const { selectedUser } = useSelector(({ Charts }) => Charts);
  const getTime = () => {
    let dateObj = new Date(chart?.createdAt?.seconds);
    return dateObj;
  };
  return (
    <div className="grid grid-cols-3 ">
      <div className="md:space-x-2 space-y-2 md:space-y-0 col-span-2 flex flex-wrap">
        <img
          src={selectedUser?.avatar || userImg}
          alt="profile"
          className="w-16 h-16 rounded-circle border-lightgreen border-1"
        />

        <div className="flex flex-col items-end text-gray-500">
          {chart?.type === "imageAttachment" ? (
            <div>
              <img
                src={chart?.media}
                alt="attached image"
                className="object-cover h-40 w-40"
              />
            </div>
          ) : (
            <p className="p-3 rounded-tl-3xl rounded-br-3xl bg-paleblue text-gray-500 text-sm w-48 md:w-64">
              {chart?.text}
            </p>
          )}
          <Moment className="text-xs justify-self-start" format="LLLL" fromNow>
            {chart.createdAt.toDate()}
          </Moment>
        </div>
      </div>
    </div>
  );
}

export default ReceivedConversation;
