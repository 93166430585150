import e from "cors";
import React, { useState } from "react";
import { useAlert } from "react-alert";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import { createSubscriptionPlan } from "../../store/actions";
import moment from "moment";

function PlanForm() {
  const { errors, isCreatingSubscription } = useSelector(
    ({ Subscriptions }) => Subscriptions
  );

  const [planName, setPlanName] = useState(null);
  const [planType, setPlanType] = useState(null);
  const [planDuration, setPlanDuration] = useState(null);
  const [planPrice, setPlanPrice] = useState(null);
  const [workingHoursFrom, setWorkingHoursFrom] = useState(null);
  const [workingHoursTo, setWorkingHoursTo] = useState(null);
  const [workingHours, setWorkingHours] = useState(null);
  const [workingHoursTime, setWorkingHoursTime] = useState(null);
  const [discountDays, setDiscountDays] = useState(null);
  const [discountAmount, setDiscountAmount] = useState(null);
  const [discountCondition, setDiscountCondition] = useState("");
  const [formErrors, setFormErrors] = useState({});
  const dispatch = useDispatch();
  const alert = useAlert();
  const dateDifference = (startDate, endDate) => {
    setFormErrors({});
    var startTime = moment(startDate, "HH:mm");
    var endTime = moment(endDate, "HH:mm");
    var duration = moment.duration(endTime.diff(startTime));
    var hours = parseInt(duration.asHours());
    var minutes = parseInt(duration.asMinutes()) - hours * 60;
    // setWorkingHours(hours);
    setWorkingHours(" " + hours + "hours and " + minutes + " minutes.");
    setWorkingHoursTime(" " + hours + "hours and " + minutes + " minutes.");
    if (hours < 0) {
      setFormErrors({
        ...formErrors,
        workingHours:
          "Time to finish work should greater than the time to start work",
      });
    } else if (hours === 0) {
      setFormErrors({
        ...formErrors,
        workingHours: "Wowking hours should be at least 1 hour",
      });
    } else {
      setFormErrors({});
    }
    return "hours, " + hours + ",and minutes, " + minutes + ".";
  };
  return (
    <div className="col-span-2 space-y-6 bg-white rounded-xl p-4 overflow-hidden ">
      <div className="flex justify-center">
        <h2 className="text-darkblue font-bold text-xl text-center form-title relative ">
          Create a New Plan
        </h2>
      </div>

      <div className="flex justify-center">
        <form class="w-full max-w-lg" onSubmit={onSubmit}>
          <div class="flex flex-wrap -mx-3 mb-6">
            <div class="w-full  px-3 mb-6 md:mb-0">
              <label
                class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="plan-name"
              >
                Plan Name
              </label>
              <input
                onChange={(e) => setPlanName(e.target.value)}
                class="appearance-none block w-full  text-gray-700 border border-red-500 rounded py-2 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                id="plan-name"
                type="text"
                placeholder="Plan name"
              />
              {errors?.plan_name ? (
                <p className="text-sm py-2 px-3 bg-red-500 text-white col-span-2">
                  {errors?.plan_name}
                </p>
              ) : null}
            </div>
          </div>
          <div class="flex flex-wrap -mx-3 mb-6">
            <div class="w-full  px-3 mb-6 md:mb-0">
              <label
                class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="grid-first-name"
              >
                Plan type
              </label>
              <select
                onChange={(e) => setPlanType(e.target.value)}
                className=" block w-100  text-gray-700 border border-red-500 rounded py-2 px-4 mb-3 leading-tight focus:outline-none focus:bg-white "
              >
                <option value="Hospital Plan" className="text-sm">
                  Hospital
                </option>
                <option value="Home Care Plan" className="text-sm">
                  Home Care
                </option>
              </select>
              {errors?.type ? (
                <p className="text-sm py-2 px-3 bg-red-500 text-white col-span-2">
                  {errors?.type}
                </p>
              ) : null}
            </div>
          </div>

          <div class="flex flex-wrap -mx-3 mb-6">
            <div class="w-full  px-3 mb-6 md:mb-0">
              <label
                class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="plan-duration"
              >
                Plan Duration
              </label>
              <input
                onChange={(e) => setPlanDuration(e.target.value)}
                class="appearance-none block w-full  text-gray-700 border border-red-500 rounded py-2 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                id="plan-duration"
                type="text"
                placeholder="specify the time"
              />
              {errors?.duration ? (
                <p className="text-sm py-2 px-3 bg-red-500 text-white col-span-2">
                  {errors?.duration}
                </p>
              ) : null}
            </div>
          </div>
          <div class="flex flex-wrap -mx-3 mb-6">
            <div class="w-full px-3">
              <label
                class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="price"
              >
                Price per Day
              </label>
              <input
                onChange={(e) => setPlanPrice(e.target.value)}
                class="appearance-none block w-full  text-gray-700 border border-gray-200 rounded py-2 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-lightgreen"
                id="price"
                type="number"
                placeholder="Enter price"
              />
              {errors?.price ? (
                <p className="text-sm py-2 px-3 bg-red-500 text-white col-span-2">
                  {errors?.price}
                </p>
              ) : null}
            </div>
          </div>
          <div class="flex flex-wrap -mx-3 mb-6">
            <div class="w-full px-3">
              <label
                class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="grid-password"
              >
                Working hours
              </label>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-2 aligns-center">
                <div class=" mb-6">
                  <label
                    class="block text-gray-500 font-bold mb-1 md:mb-0 pr-4"
                    for="inline-full-name"
                    style={{ fontSize: "14px" }}
                  >
                    From{" "}
                    <span className="px-2 text-xs text-gray-500 font-normal">
                      ( eg: 8:00 am )
                    </span>
                  </label>

                  <div class="">
                    <input
                      onChange={(e) => {
                        if (workingHoursTo) {
                          setWorkingHoursFrom(e.target.value);
                          dateDifference(workingHoursFrom, e.target.value);
                        } else {
                          setWorkingHoursFrom(e.target.value);
                        }
                      }}
                      class="border-gray-200 border-2  rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-lightgreen"
                      id="inline-full-name"
                      type="time"
                      placeholder="eg: 8:00 am"
                    />
                  </div>
                </div>
                <div class=" mb-6">
                  <label
                    class="block text-gray-500 font-bold  mb-1 md:mb-0 pr-4"
                    for="inline-full-name"
                    style={{ fontSize: "14px" }}
                  >
                    To{" "}
                    <span className="px-2 text-xs text-gray-500 font-normal">
                      ( eg: 8:00 pm )
                    </span>
                  </label>

                  <div class="">
                    <input
                      onChange={(e) => {
                        if (workingHoursFrom) {
                          dateDifference(workingHoursFrom, e.target.value);
                          setWorkingHoursTo(e.target.value);
                        } else {
                          setWorkingHoursTo(e.target.value);
                        }
                      }}
                      class="border-gray-200 r appearance-none border-2 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-lightgreen"
                      id="inline-full-name"
                      type="time"
                      placeholder="eg:8:00 pm"
                    />
                  </div>
                </div>
              </div>
              {errors?.working_hours ? (
                <p className="text-sm py-2 px-3 bg-red-500 text-white col-span-2">
                  {errors?.working_hours}
                </p>
              ) : null}
              {formErrors?.workingHours ? (
                <p className="text-sm py-2 px-3 bg-red-500 text-white">
                  {formErrors?.workingHours}
                </p>
              ) : null}
              {workingHoursTime && (
                <div class=" mb-6">
                  <label
                    class="block text-gray-500 font-bold  mb-1 md:mb-0 pr-4"
                    for="inline-full-name"
                    style={{ fontSize: "14px" }}
                  >
                    Number of hours
                  </label>

                  <div class="md:w-2/3">
                    {/* <input
                    onChange={(e) => setWorkingHours(e.target.value)}
                    class=" appearance-none border-2 border-gray-200 rounded  py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-lightgreen"
                    id="inline-full-name"
                    type="number"
                    placeholder="eg: 12 Hours"
                  /> */}
                    <p className="text-sm py-2 font-bold  text-lightgreen">
                      {workingHoursTime}
                    </p>

                    {errors?.no_of_hours ? (
                      <p className="text-sm py-2 px-3 bg-red-500 text-white">
                        {errors?.no_of_hours}
                      </p>
                    ) : null}
                  </div>
                </div>
              )}
            </div>
          </div>

          <div class="flex flex-wrap -mx-3 mb-6">
            <div class="w-full px-3">
              <label
                class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="grid-password"
              >
                Discount
              </label>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div class="">
                  <label
                    class="block text-gray-500 font-bold  mb-1 md:mb-0 pr-4"
                    for="discount-days"
                    style={{ fontSize: "14px" }}
                  >
                    Days to Discount
                  </label>
                  <input
                    onChange={(e) => setDiscountDays(e.target.value)}
                    class=" appearance-none border-2 border-gray-200 rounded  py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-lightgreen"
                    id="discount-days"
                    type="number"
                    placeholder="eg: 10 days"
                  />

                  {errors?.discount_days ? (
                    <p className="text-sm py-2 px-3 bg-red-500 text-white">
                      {errors?.discount_days}
                    </p>
                  ) : null}
                </div>
                <div>
                  <label
                    class="block text-gray-500 font-bold  mb-1 md:mb-0 pr-4"
                    for="discount-amount"
                    style={{ fontSize: "14px" }}
                  >
                    Percentage (%)
                  </label>
                  <input
                    onChange={(e) => setDiscountAmount(e.target.value)}
                    class=" appearance-none border-2 border-gray-200 rounded  py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-lightgreen"
                    id="discount-amount"
                    type="number"
                    placeholder="eg:20%"
                  />
                  {errors?.discount ? (
                    <p className="text-sm py-2 px-3 bg-red-500 text-white">
                      {errors?.discount}
                    </p>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          <div class="flex flex-wrap -mx-3 mb-6">
            <div class="w-full px-3">
              <label
                class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="condition"
              >
                Condition
              </label>
              <input
                onChange={(e) => setDiscountCondition(e.target.value)}
                class="appearance-none block w-full text-gray-700 border border-gray-200 rounded py-2 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-lightgreen"
                id="condition"
                type="text"
                placeholder="Create plan condition"
              />
              {errors?.description ? (
                <p className="text-sm py-2 px-3 bg-red-500 text-white">
                  {errors?.description}
                </p>
              ) : null}
            </div>
          </div>
          <div
            class="w-100 flex items-center justify-center bg-cyan-400  shadow hover:bg-cyan-400 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded"
            style={{ backgroundColor: "#34BEB9" }}
          >
            <button class="" type="submit">
              {isCreatingSubscription ? "Saving ..." : " Save this plan"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
  function onSubmit(e) {
    e.preventDefault();
    if (formErrors?.workingHours || isCreatingSubscription) return;
    if (isCreatingSubscription) return;
    const plan_name = planName;
    const price = planPrice;
    const duration = planDuration;
    const type = planType;
    var working_hours =
      workingHoursFrom && workingHoursTo
        ? "" + workingHoursFrom + "am - " + workingHoursTo + "pm"
        : "";

    const no_of_hours = workingHours;
    const discount = discountAmount;
    const discount_days = discountDays;
    const description = discountCondition;
    const data = {
      plan_name,
      price,
      duration,
      type,
      working_hours,
      no_of_hours,
      discount,
      discount_days,
      description,
    };

    dispatch(createSubscriptionPlan(data, alert));
  }
}

export default PlanForm;
