import { onAuthStateChanged } from "firebase/auth";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import userImg from "../../assets/images/user_img.png";
import { auth } from "../../firebase";
import {
  getFirebaseAdminUser,
  getUsersListTrial,
  onSetSelectedUser,
} from "../../store/actions";
import Shimmers from "../loaders/Shimmers";
import { IoMdImage } from "react-icons/io";
function LatestMessages() {
  const {
    charts,
    selectedChart,
    selectedUser,
    usersList,
    latestMessages,
    isFetchingUsersList,
    isFetchingLatestMessages,
  } = useSelector(({ Charts }) => Charts);
  const { firebaseUserInfo, isFetchingFirebaseUser } = useSelector(
    ({ UserAuth }) => UserAuth
  );
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (firebaseUserInfo) {
      dispatch(getUsersListTrial(firebaseUserInfo));
      // dispatch(getLatestMessages());
    }
  }, [firebaseUserInfo]);

  return (
    <div>
      <h2 className="font-semibold text-darkblue text-xl my-2">Messages</h2>
      <div className="space-y-2">
        {isFetchingFirebaseUser ||
        isFetchingUsersList ||
        isFetchingLatestMessages ? (
          <div className="space-y-2">
            <Shimmers width="100%" height="60px" />
            <Shimmers width="100%" height="60px" />
            <Shimmers width="100%" height="60px" />
          </div>
        ) : (
          usersList.slice(0, 3).map((user, index) => (
            <div key={index} className="p-2 flex space-x-3 bg-white rounded-2">
              <div className="relative  flex items-center w-14 h-14">
                <img
                  src={user?.avatar || userImg}
                  alt="Profile"
                  className="w-14 h-14 object-cover rounded-circle "
                />
              </div>
              <div className="flex-1">
                <p className="text-base text-darkblue font-bold">
                  {user?.name}
                </p>
                <span className="text-xs text-gray-700">
                  {latestMessages?.length > 0 &&
                    latestMessages.map((msg, index) =>
                      msg?.from === user?.uid || msg?.to === user?.uid ? (
                        msg?.type === "imageAttachment" ? (
                          <IoMdImage className="text-2xl text-lightgreen" />
                        ) : (
                          <span key={index} className="text-xs text-gray-500">
                            {msg?.text?.length > 20
                              ? "" + msg.text.substring(0, 20) + " ..."
                              : msg?.text}
                          </span>
                        )
                      ) : null
                    )}
                </span>
                <button
                  onClick={() => {
                    dispatch(onSetSelectedUser(user, firebaseUserInfo));
                    history.push("/dashboard/charts");
                  }}
                  className="text-sm text-lightgreen hover:opacity-70 block w-100 text-end"
                >
                  View Message
                </button>
              </div>
            </div>
          ))
        )}
        {!isFetchingFirebaseUser &&
          !isFetchingUsersList &&
          !isFetchingLatestMessages &&
          usersList?.length === 0 && (
            <p className="  py-3 text-sm  text-gray-800">no messages found.</p>
          )}
      </div>
    </div>
  );
}

export default LatestMessages;
