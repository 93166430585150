import { Tab } from "bootstrap";
import React from "react";
import PlanForm from "../../components/pricing/PlanForm";
import PlansList from "../../components/pricing/PlansList";
function Pricing() {
  return (
    <div className="md:pt-20 pb-6 mx-3.5 sm:mx-10 space-y-6  ">
      <div>
        <h5 className="text-xl leading-10 text-darkblue font-bold">Pricing</h5>
        <p className="text-base font-semibold leading-6 text-lightergray">
          All Pricing information
        </p>
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
        <div className="mr-3 flex-1">
          <PlanForm />
        </div>
        <div className="flex-1">
          <PlansList />
        </div>
      </div>
    </div>
  );
}

export default Pricing;
