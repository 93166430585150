import React from "react";
import { ImSearch } from "react-icons/im";
import { FaSlidersH } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { getPendingNurses } from "../../store/actions";

function NameFilter({ searchText, setSearchText }) {
  const dispatch = useDispatch();
  return (
    <div className="flex space-x-3 items-center	justify-center mr-2 md:mr-0	">
      <input
        id="searchtxt"
        name="searchtxt"
        placeholder="search name"
        type="searchtxt"
        onChange={(e) => setSearchText(e.target.value)}
        required
        className="appearance-none rounded-none relative block w-full px-3 py-2 placeholder-lightergray text-lightergray rounded-t-md focus:outline-none focus:ring-lightgreen focus:border-lightgreen focus:z-10 sm:text-sm"
      />
      {/* <div
        style={{
          backgroundColor: "#1E355C",
          padding: "8px",
          borderRadius: "5px",
        }}
      >
        <FaSlidersH className=" flex " style={{ color: "#fff" }} />
      </div> */}
    </div>
  );
}

export default NameFilter;
