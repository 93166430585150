import { async } from "@firebase/util";
import { data } from "autoprefixer";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  orderBy,
  query,
  setDoc,
  Timestamp,
  updateDoc,
  where,
} from "firebase/firestore";
import { fetchNursesRequest } from "../../api";
import { auth, db } from "../../firebase";
import { GetValidImage } from "../../utils/image";
import actionTypes from "./types";
export const setOpenCharts = (state) => {
  return (dispatch, getState) => {
    const openCharts = getState().Charts.openCharts;
    dispatch({ type: actionTypes.setOpenCharts, payload: state });
  };
};

export const getUsersListTrial = (currentUser) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.getUsersList });
    const usersRef = collection(db, "users");
    const q = query(usersRef, where("uid", "not-in", [currentUser.uid]));
    const unsubz = onSnapshot(q, (querySnapshot) => {
      let users = [];
      querySnapshot.forEach((doc) => {
        users.push(doc.data());
      });
      dispatch(getLatestMessagesTrial(users, currentUser));
    });
  };
};
export const getLatestMessagesTrial = (users, currentUser) => {
  return (dispatch, getState) => {
    dispatch({ type: actionTypes.getLatestMessages });
    const selectedUser = getState().Charts.selectedUser;
    const lastMsgsRef = collection(db, "lastMsg");
    const q = query(lastMsgsRef, orderBy("createdAt", "desc"));

    const messages = onSnapshot(q, (querySnapshot) => {
      let lastMsgs = [];
      let usersWithMessages = [];

      querySnapshot.forEach((doc) => {
        lastMsgs.push(doc.data());

        var userWithMessages = users?.find(
          (user) =>
            user?.uid === doc.data()?.from || user?.uid === doc.data()?.to
        );
        if (typeof userWithMessages !== "undefined") {
          usersWithMessages.push(userWithMessages);
        }
      });
      if (users?.length !== usersWithMessages?.length) {
        if (usersWithMessages?.length !== 0) {
          var newUsersArray = usersWithMessages.concat(users); //first joininng both arrays in one and storing it in newArray

          var usersWithoutMessagesArray = [];

          for (var i = 0; i < newUsersArray.length; i++) {
            //NOW COMPARING EACH ELEMENT OF  newArray  WITH ARR1 AD ARR2 AND PUSHING NOT SAME VALUES TO newestArray
            if (usersWithMessages.indexOf(newUsersArray[i]) === -1) {
              usersWithoutMessagesArray.push(newUsersArray[i]);
            }
            if (users.indexOf(newUsersArray[i]) === -1) {
              usersWithoutMessagesArray.push(newUsersArray[i]);
            }
          }
          if (usersWithoutMessagesArray?.length) {
            var AllUsersList = usersWithMessages.concat(
              usersWithoutMessagesArray
            );

            dispatch({
              type: actionTypes.getUsersListSuccess,
              payload: AllUsersList,
            });
          }
        } else {
          dispatch({ type: actionTypes.getUsersListSuccess, payload: users });
        }
      } else {
        dispatch({ type: actionTypes.getUsersListSuccess, payload: users });
      }
      dispatch({
        type: actionTypes.getLatestMessagesSuccess,
        payload: lastMsgs,
      });
    });
  };
};
export const searchUsersByName = (searchText, currentUser) => {
  return (dispatch, getState) => {
    const userInfo = getState().UserAuth.firebaseUserInfo;
    dispatch({ type: actionTypes.searchUsersByName });
    const usersRef = collection(db, "users");
    const q = query(usersRef, where("uid", "not-in", [userInfo?.uid]));
    const unsubz = onSnapshot(q, (querySnapshot) => {
      let users = [];
      querySnapshot.forEach((doc) => {
        users.push(doc.data());
      });
      if (users?.length > 0) {
        if (searchText) {
          var searchUsers = users.filter((user) =>
            user?.name.toLowerCase().includes(searchText)
          );
          dispatch({
            type: actionTypes.searchUsersByNameSuccess,
            payload: searchUsers,
          });
          if (searchUsers?.length > 0) {
            dispatch(onSetSelectedUser(searchUsers[0], currentUser));
          }
        } else {
          dispatch({
            type: actionTypes.searchUsersByNameSuccess,
            payload: users,
          });

          dispatch(onSetSelectedUser(users[0], currentUser));
        }
      }
    });
  };
};

export const onSetSelectedUser = (user, currentUser) => {
  return async (dispatch) => {
    dispatch({ type: actionTypes.setSelectedUser, payload: user });
  };
};
export const onSetSelectedChart = (user, currentUser) => {
  return async (dispatch) => {
    // dispatch({ type: actionTypes.setSelectedUser, payload: user });
    const user1 = currentUser?.uid;
    const user2 = user.uid;
    const id = user1 > user2 ? `${user1 + user2}` : `${user2 + user1}`;
    const msgsRef = collection(db, "chatRoom", id, "chat");
    const q = query(msgsRef, orderBy("createdAt", "asc"));
    const querySnapshot = await getDocs(q);
    let msgs = [];
    querySnapshot.forEach((doc) => {
      // updateDoc(doc.ref, { unread: false });
      msgs.push(doc.data());
    });
    if (querySnapshot.size !== 0) {
      dispatch({ type: actionTypes.setSelectedChart, payload: msgs });
      dispatch(markMessagesRead(user));
    } else {
      dispatch({ type: actionTypes.setSelectedChart, payload: msgs });
    }
    // onSnapshot(q, (querySnapshot) => {
    //   let msgs = [];
    //   querySnapshot.forEach((doc) => {
    //     // updateDoc(doc.ref, { unread: false });
    //     msgs.push(doc.data());
    //   });
    //   dispatch({ type: actionTypes.setSelectedChart, payload: msgs });
    //   dispatch(markChartRead(user));
    // });
    // const docSnap = await getDoc(doc(db, "lastMsg", id));
    // if (docSnap.data() && docSnap.data().from !== user1) {
    //   await updateDoc(doc(db, "lastMsg", id), { unread: false });
    // }
  };
};

export const getNewChartNurses = (searchText) => {
  return (dispatch, getState) => {
    const usersList = getState().Charts.usersList;
    dispatch({ type: actionTypes.getNewChartNurses });
    fetchNursesRequest(searchText)
      .then((res) => {
        if (res?.data && res?.data?.length !== 0) {
          var nursesList = res?.data.reduce((acc, data) => {
            var nurseExistingInChat = usersList?.find(
              (user) => user?.uid === data?.id
            );
            if (typeof nurseExistingInChat === "undefined") {
              acc.push(data);
            }
            return acc;
          }, []);
          dispatch({
            type: actionTypes.getNewChartNursesSuccess,
            payload: nursesList,
          });
        } else {
          dispatch({ type: actionTypes.getNewChartNursesSuccess, payload: [] });
        }
      })
      .catch((err) => {
        dispatch({ type: actionTypes.getNewChartNursesFail, payload: err });
      });
  };
};
export const saveFirebaseUser = (user, role) => {
  return async (dispatch, getState) => {
    const firebaseUserInfo = getState().UserAuth.firebaseUserInfo;
    try {
      await setDoc(doc(db, "users", user?.id), {
        uid: user?.id,
        name: user?.full_names,
        email: user?.email,
        createdAt: Timestamp.fromDate(new Date()),
        isOnline: true,
        role: role,
        avatar: GetValidImage(user?.avatar) || "",
      });
      await dispatch(getUsersListTrial(firebaseUserInfo));
    } catch (err) {
      console.log("Error!", err);
    }
  };
};
export const onSelectedNewChartNurse = (nurse) => {
  return async (dispatch) => {
    const role = "Nurse";
    dispatch({ type: actionTypes.setSelectedNewChartNurse, payload: nurse });
    dispatch(saveFirebaseUser(nurse, role));
  };
};
export const getTotalNewChart = () => {
  return (dispatch, getState) => {
    const firebaseAdminUser = getState().UserAuth.firebaseUserInfo;
    dispatch({ type: actionTypes.getTotalNewChart });

    const lastMsgsRef = collection(db, "lastMsg");
    const q = query(lastMsgsRef);
    const messages = onSnapshot(q, (querySnapshot) => {
      let lastMsgs = [];
      querySnapshot.forEach((doc) => {
        lastMsgs.push(doc.data());
      });

      if (lastMsgs?.length > 0) {
        var sum = 0;

        var totalNewCharts = lastMsgs?.reduce((acc, data) => {
          if (data?.from !== firebaseAdminUser?.uid && data?.unread === true) {
            sum = sum + 1;
            // return sum;
          }
          return sum;
        }, "");

        dispatch({
          type: actionTypes.getTotalNewChartSuccess,
          payload: totalNewCharts,
        });
      } else {
        dispatch({
          type: actionTypes.getTotalNewChartFail,
          payload: null,
        });
      }
    });
  };
};
export const getTotalUserUnreadMessages = () => {
  return (dispatch, getState) => {
    dispatch({ type: actionTypes.getTotalUserUnreadMessages });
    const firebaseAdminUser = getState().UserAuth.firebaseUserInfo;
    const userList = getState().Charts.usersList;

    var totalMessages = userList.reduce((acc, data, index) => {
      const user1 = firebaseAdminUser?.uid;
      const user2 = data?.uid;
      const id = user1 > user2 ? `${user1 + user2}` : `${user2 + user1}`;
      const msgsRef = collection(db, "chatRoom", id, "chat");
      const q = query(msgsRef, orderBy("createdAt", "asc"));

      onSnapshot(q, (querySnapshot) => {
        let msgs = [];
        querySnapshot.forEach((doc) => {
          msgs.push(doc.data());
        });
        let messagesCountList = [];
        var sum = 0;
        if (msgs?.length > 0) {
          var totalNewMessages = msgs?.reduce((acc, data) => {
            if (
              data?.from !== firebaseAdminUser?.uid &&
              data?.unread === true
            ) {
              sum = sum + 1;
              // return sum;
            }
            return sum;
          }, "");

          acc?.push({ uid: user2, unreadMessagesSum: totalNewMessages });
          messagesCountList.push({
            uid: user2,
            unreadMessagesSum: totalNewMessages,
          });

          if (index === userList?.length - 1) {
            dispatch({
              type: actionTypes.getTotalUserUnreadMessagesSuccess,
              payload: acc,
            });
          }
        } else {
          if (index === userList?.length - 1) {
            dispatch({
              type: actionTypes.getTotalUserUnreadMessagesSuccess,
              payload: acc,
            });
          }
        }
      });

      return acc;
    }, []);

    // dispatch(getTotalUserUnreadMessages2());
    // dispatch({
    //   type: actionTypes.getTotalUserUnreadMessagesSuccess,
    //   payload: totalMessages,
    // });
  };
};
export const getMsgs = () => {
  return async (dispatch) => {};
};
export const getTotalUserUnreadMessages2 = () => {
  return (dispatch, getState) => {
    dispatch({ type: actionTypes.getTotalUserUnreadMessages });
    const firebaseAdminUser = getState().UserAuth.firebaseUserInfo;
    const userList = getState().Charts.usersList;

    var totalMessages = [];
    userList.map(async (user, index) => {
      const user1 = firebaseAdminUser?.uid;
      const user2 = user?.uid;
      const id = user1 > user2 ? `${user1 + user2}` : `${user2 + user1}`;
      const msgsRef = collection(db, "chatRoom", id, "chat");
      const q = query(msgsRef, orderBy("createdAt", "asc"));
      var sum = 0;

      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        if (
          doc.data()?.from !== firebaseAdminUser?.uid &&
          doc.data()?.unread === true
        ) {
          sum = sum + 1;
        }
      });
      if (querySnapshot.size !== 0) {
        totalMessages.push({ uid: user2, unreadMessagesSum: sum });
        if (index === userList?.length - 1) {
          dispatch({
            type: actionTypes.getTotalUserUnreadMessagesSuccess,
            payload: totalMessages,
          });
        }
      } else {
        console.log("the sum:", sum, user);
      }
    });
  };
};
export const markChartRead = (user) => {
  return async (dispatch, getState) => {
    const firebaseUserInfo = getState().UserAuth.firebaseUserInfo;
    dispatch({ type: actionTypes.markChartRead });

    const user1 = firebaseUserInfo?.uid;
    const user2 = user.uid;
    const id = user1 > user2 ? `${user1 + user2}` : `${user2 + user1}`;
    const docSnap = await getDoc(doc(db, "lastMsg", id));
    if (docSnap.data() && docSnap.data().from !== user1) {
      await updateDoc(doc(db, "lastMsg", id), { unread: false });
    }
    // dispatch(markMessagesRead(user));
    dispatch({
      type: actionTypes.markChartReadSuccess,
      payload: null,
    });
  };
};
export const markMessagesRead = (user) => {
  return async (dispatch, getState) => {
    dispatch({ type: actionTypes.markMessagesRead });
    const firebaseAdmin = getState().UserAuth.firebaseUserInfo;
    const user1 = firebaseAdmin?.uid;
    const user2 = user?.uid;
    const id = user1 > user2 ? `${user1 + user2}` : `${user2 + user1}`;

    const msgsRef = collection(db, "chatRoom", id, "chat");
    const q = query(msgsRef, orderBy("createdAt", "asc"));
    // const q = query(collection(db, "cities"), where("capital", "==", true));

    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      if (
        doc.data()?.from !== firebaseAdmin?.uid &&
        doc.data()?.unread === true
      ) {
        updateDoc(doc.ref, { unread: false });
      }
    });
    dispatch(markChartRead(user));
    dispatch({
      type: actionTypes.markMessagesReadSuccess,
      payload: null,
    });
  };
};
