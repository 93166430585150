import React, { useEffect, useState } from "react";
import { ImSearch } from "react-icons/im";
import { useDispatch, useSelector } from "react-redux";
import Conversation from "../../components/chat/Conversation";

import MessagesList from "../../components/chat/MessagesList";
import { onAuthStateChanged } from "firebase/auth";
import { auth, colRef, db, storage } from "../../firebase";
import {
  collection,
  query,
  where,
  onSnapshot,
  addDoc,
  Timestamp,
  orderBy,
  setDoc,
  doc,
  getDoc,
  updateDoc,
} from "firebase/firestore";
import { ref, getDownloadURL, uploadBytes } from "firebase/storage";
import {
  getFirebaseAdminUser,
  getLatestMessages,
  getTotalUserUnreadMessages,
  getTotalUserUnreadMessages2,
  onSetSelectedChart,
  onSetSelectedUser,
  searchUsersByName,
  setOpenCharts,
  markMessagesRead,
  getUsersListTrial,
} from "../../store/actions";
import Shimmers from "../../components/loaders/Shimmers";
import { Modal } from "react-bootstrap";
import NewChatUsersList from "../../components/chat/NewChatUsersList";
function Charts() {
  const {
    charts,
    selectedChart,
    selectedUser,
    usersList,
    latestMessages,
    totalNewCharts,
    totalUserUnreadMessages,
    isFetchingUsersList,
    isFetchingTotalUserUnreadMessages,
    isFetchingTotalNewCharts,
    isFetchingLatestMessages,
  } = useSelector(({ Charts }) => Charts);
  const { firebaseUserInfo, isFetchingFirebaseUser } = useSelector(
    ({ UserAuth }) => UserAuth
  );

  const [latestUnreadMessages, setLatestUnreadMessages] = useState([]);
  const [searchText, setSearchText] = useState("no text");
  const [openNursesListModal, setOpenNursesListModal] = useState(false);
  const [openStudentsListModal, setOpenStudentsListModal] = useState(false);
  const dispatch = useDispatch();
  const getLatestUnreadMessages = () => {
    var latestUnreadMsgs = latestMessages.reduce((acc, data) => {
      if (data?.from === firebaseUserInfo?.uid && data?.unread === true) {
        acc.push(data);
      }
      return acc;
    }, []);
    setLatestUnreadMessages(latestUnreadMsgs);
  };
  // useEffect(() => {
  //   dispatch(getFirebaseAdminUser());
  // }, []);
  const handleClose = () => {
    setOpenNursesListModal(false);
    setOpenStudentsListModal(false);
  };
  useEffect(() => {
    if (firebaseUserInfo) {
      // dispatch(getUsersList(firebaseUserInfo));
      // dispatch(getLatestMessages());
      dispatch(getUsersListTrial(firebaseUserInfo));
    }
  }, [firebaseUserInfo]);
  useEffect(() => {
    if (
      !isFetchingUsersList &&
      !isFetchingLatestMessages &&
      usersList?.length !== 0 &&
      latestMessages?.length !== 0 &&
      selectedUser === null
    ) {
      dispatch(onSetSelectedUser(usersList[0], firebaseUserInfo));
    }
  }, [
    usersList,
    isFetchingUsersList,
    isFetchingLatestMessages,
    latestMessages,
  ]);
  useEffect(() => {
    if (selectedUser) {
      dispatch(onSetSelectedChart(selectedUser, firebaseUserInfo));
    }
  }, [selectedUser]);
  useEffect(() => {
    if (selectedUser) {
      dispatch(onSetSelectedChart(selectedUser, firebaseUserInfo));
    }
  }, [latestMessages]);
  useEffect(() => {
    dispatch(setOpenCharts(true));
    return () => {
      dispatch(setOpenCharts(false));
    };
  }, []);

  useEffect(() => {
    if (!isFetchingUsersList && !isFetchingLatestMessages) {
      getLatestUnreadMessages();
    }
  }, [isFetchingUsersList, isFetchingLatestMessages]);
  // useEffect(() => {
  //   if (latestMessages && usersList) {
  //     dispatch(getTotalUserUnreadMessages2());
  //   }
  // }, [latestMessages, usersList]);
  useEffect(() => {
    if (usersList && latestMessages) {
      dispatch(getTotalUserUnreadMessages());

      // dispatch(onSetSelectedChart(selectedUser, firebaseUserInfo));
    }
  }, [usersList, latestMessages]);
  useEffect(() => {
    if (latestMessages && selectedUser) {
      var unreadSelectedUser = latestMessages?.find(
        (msg) => msg?.from === selectedUser?.uid
      );
      if (
        typeof unreadSelectedUser !== "undefined" &&
        unreadSelectedUser?.unread === true
      ) {
        dispatch(markMessagesRead(selectedUser));
        dispatch(getTotalUserUnreadMessages());
      }
    }
  }, [latestMessages, selectedUser]);
  useEffect(() => {
    if (firebaseUserInfo) {
      if (searchText) {
        if (searchText !== "no text") {
          dispatch(searchUsersByName(searchText, firebaseUserInfo));
        }
      } else {
        dispatch(searchUsersByName(searchText, firebaseUserInfo));
      }
    }
  }, [
    searchText,
    firebaseUserInfo,
    isFetchingUsersList,
    isFetchingLatestMessages,
  ]);
  return (
    <div className="grid grid-cols-1 lg:grid-cols-3 min-h-screen md:pt-20 pb-6 px-3.5 sm:px-10 gap-y-4 lg:gap-x-4">
      {isFetchingFirebaseUser ||
      isFetchingTotalNewCharts ||
      isFetchingUsersList ||
      // isFetchingTotalUserUnreadMessages ||
      isFetchingLatestMessages ? (
        <div>
          <Shimmers width="100%" height="80vh" />
        </div>
      ) : (
        <div className="space-y-6 h-96 lg:h-carditem lg:sticky lg:top-0  overflow-auto">
          <div className="flex items-center space-x-6">
            <h5 className="text-xl leading-10 text-darkblue font-bold">
              Inbox
            </h5>
            <p className="text-sm py-2 px-4 bg-lightgreen text-white rounded-xl">
              {totalNewCharts ?? "0"} New Chat
            </p>
          </div>

          <div className="flex justify-between flex-wrap">
            <div className="flex items-center  bg-white rounded-3xl py-2.5 px-3 space-x-3 mt-2 sm:mt-0">
              <ImSearch className="text-darkblue" />
              <input
                type="text"
                id="search_text"
                name="search_text"
                placeholder="Search chat"
                className="text-sm text-darkblue flex-1"
                onChange={(e) => setSearchText(e.target.value)}
              />
            </div>
            <button
              onClick={() => setOpenNursesListModal(true)}
              className="align-self-start text-sm text-whitecolor bg-lightgreen rounded-xl opacity-70 hover:opacity-100 py-2 px-3 mt-2 sm:mt-0"
            >
              Add Chat{" "}
            </button>
          </div>
          <MessagesList users={usersList} currentUser={firebaseUserInfo} />
        </div>
      )}
      {isFetchingFirebaseUser ||
      isFetchingTotalNewCharts ||
      isFetchingUsersList ||
      // isFetchingTotalUserUnreadMessages ||
      isFetchingLatestMessages ? (
        <div className="col-span-2 ">
          <Shimmers width="100%" height="80vh" />
        </div>
      ) : (
        <div className="col-span-2 ">
          <Conversation currentUser={firebaseUserInfo} />
        </div>
      )}
      <Modal show={openNursesListModal} onHide={handleClose}>
        <NewChatUsersList handleClose={handleClose} />
      </Modal>
    </div>
  );
}

export default Charts;
