import { fetchIncomesRequest } from "../../api";
import actionTypes from "./types";
import html2pdf from "html2pdf.js";
export const getIncomes = (searchText, selectedStartDate, selectedEndDate) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.getIncomes });
    fetchIncomesRequest(
      searchText ?? null,
      selectedStartDate ?? null,
      selectedEndDate ?? null
    )
      .then((res) => {
        if (res && res.data) {
          if (res?.data?.length !== 0) {
            var sum = 0;
            var incomeSum = res.data.reduce((acc, data) => {
              sum = parseInt(data?.amount) + sum;
              return sum;
            }, "");
            dispatch({ type: actionTypes.getIncomeSum, payload: incomeSum });
          }
          dispatch({ type: actionTypes.getIncomesSuccess, payload: res.data });
        }
      })
      .catch((err) =>
        dispatch({ type: actionTypes.getIncomesFail, payload: err })
      );
  };
};
export const filterIncomeByDate = () => {
  return (dispatch) => {
    dispatch({ type: actionTypes.filterIncomesByDate });
  };
};
export const generatePdfIncome = () => {
  return (dispatch) => {
    var element = document.getElementById("income-to-print");
    var clonedElement = element.cloneNode(true);
    // change display of cloned element

    clonedElement.style.display = "block";
    var opt = {
      margin: 1,
      filename: "Income.pdf",
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "pt", format: "a4", orientation: "portrait" },
    };

    html2pdf().from(clonedElement).set(opt).save();
    clonedElement.remove();
  };
};
