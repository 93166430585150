import {
  addDoc,
  collection,
  doc,
  getDocs,
  query,
  setDoc,
  Timestamp,
  where,
} from "firebase/firestore";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import { db } from "../../../firebase";
import { GetValidImage } from "../../../utils/image";

function RejectedNurseAlert({ selectedNurse, alert, handleClose }) {
  const { firebaseUserInfo, isFetchingFirebaseUser } = useSelector(
    ({ UserAuth }) => UserAuth
  );
  const [message, setMessage] = useState("");
  const [messageError, setMessageError] = useState(null);
  const [isSendingMessage, setIsSendingMessage] = useState(false);
  return (
    <div>
      <Modal.Header closeButton>
        <Modal.Title>
          <div className="text-base font-semibold text-lightgreen">
            Nurse Successfully Disapproved !
          </div>{" "}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-sm px-3 space-y-4">
        <p>Inform the nurse why their account has been rejected</p>
        <textarea
          rows=""
          cols=""
          className="text-sm text-gray-700 form-control  w-100  rounded-xl border-gray-300  focus:shadow-none focus:ring-lightgreen focus:border-lightgreen focus:z-10 p-4"
          id="message"
          name="message"
          required
          onChange={(e) => setMessage(e.target.value)}
        ></textarea>
        {messageError ? (
          <p className="px-3 py-1 text-sm text-whitecolor bg-red-300 rounded-md">
            {messageError}
          </p>
        ) : null}
        <button
          onClick={sendMessage}
          className="bg-lightgreen hover:opacity-70 px-10 py-1.5 text-white text-sm rounded-xl"
        >
          {isSendingMessage ? "Sending ..." : "Send Message"}
        </button>
      </Modal.Body>
    </div>
  );
  async function send(user1, user2, id) {
    await addDoc(collection(db, "chatRoom", id, "chat"), {
      type: message ? "text" : "imageAttachment",
      text: message,
      from: user1,
      to: user2,
      createdAt: Timestamp.fromDate(new Date()),
      media: "",
      unread: true,
    });

    await setDoc(doc(db, "lastMsg", id), {
      type: message ? "text" : "imageAttachment",
      text: message,
      from: user1,
      to: user2,
      createdAt: Timestamp.fromDate(new Date()),
      media: "",
      unread: true,
    });
    setMessage("");
    setMessageError(null);
    setIsSendingMessage(false);
    handleClose();
  }
  async function sendMessage(e) {
    setMessageError(null);
    if (isSendingMessage) return;
    if (!message) {
      setMessageError("Please provide the message");
      return;
    }
    setIsSendingMessage(true);
    e.preventDefault();
    const user1 = firebaseUserInfo?.uid;
    const user2 = selectedNurse.id;
    const id = user1 > user2 ? `${user1 + user2}` : `${user2 + user1}`;
    if (selectedNurse) {
      const usersRef = collection(db, "users");
      const q = query(usersRef, where("uid", "==", selectedNurse?.id));

      const querySnapshot = await getDocs(q);

      if (querySnapshot.size === 0) {
        await setDoc(doc(db, "users", user2), {
          uid: user2,
          name: selectedNurse?.full_names,
          email: selectedNurse?.email,
          createdAt: Timestamp.fromDate(new Date()),
          isOnline: true,
          role: "Nurse",
          avatar: GetValidImage(selectedNurse?.avatar) || "",
        });

        send(user1, user2, id);
      } else {
        send(user1, user2, id);
      }
    }
  }
}

export default RejectedNurseAlert;
