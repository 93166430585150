import React, { useEffect, useState } from "react";
import userImg from "../../../assets/images/nurse 3.png";
import { ImSearch } from "react-icons/im";
import { IoCloseCircle } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  getBlockedNurses,
  getPendingNurses,
  getRejectedNurses,
  onSetSelectedNurse,
} from "../../../store/actions";
import Shimmers from "../../loaders/Shimmers";
import { GetValidImage } from "../../../utils/image";
function PendingNursesList({ nurses, pending, blocked, rejection }) {
  const {
    pendingNurses,
    isFetchingPendingNurses,
    isFetchingRejectedNurses,
    isFetchingBlockedNurses,
  } = useSelector(({ Nurses }) => Nurses);
  const [searchText, setSearchText] = useState();
  const dispatch = useDispatch();
  const history = useHistory();
  // useEffect(()=>{
  //   if(searchText==="no text"){
  //     if (pending === true) {
  //       dispatch(getPendingNurses());
  //     } else if (blocked === true) {
  //       dispatch(getBlockedNurses());
  //     } else if (rejection === true) {
  //       dispatch(getRejectedNurses());
  //     }
  //   }
  // },[searchText])
  return (
    <div className="space-y-6 overflow-auto  max-h-80 lg:h-auto">
      {isFetchingPendingNurses ||
      isFetchingRejectedNurses ||
      isFetchingBlockedNurses ? (
        <div>
          <Shimmers width="100%" height="20px" />
        </div>
      ) : (
        <div className="sm:flex space-y-2 space-x-0 sm:space-x-2 sm:space-y-0">
          <div className="flex space-x-3 flex-1 items-center bg-white rounded-2xl">
            <ImSearch className="text-gray-700 ml-2 text-sm" />
            <input
              id="searchtxt"
              name="searchtxt"
              type="searchtxt"
              onChange={(e) => setSearchText(e.target.value)}
              required
              placeholder="Search a by name"
              value={searchText}
              className="flex-1 appearance-none rounded-none relative block w-full  py-2 placeholder-darkblue text-gray-900 rounded-t-md focus:outline-none focus:ring-lightgreen focus:border-lightgreen focus:z-10 text-sm"
            />
            {searchText ? (
              <IoCloseCircle
                role="button"
                className="text-red-500 text-xl"
                onClick={() => {
                  setSearchText("no text");
                  if (pending === true) {
                    dispatch(getPendingNurses());
                  } else if (blocked === true) {
                    dispatch(getBlockedNurses());
                  } else if (rejection === true) {
                    dispatch(getRejectedNurses());
                  }
                  setSearchText(null);
                }}
              />
            ) : null}
            <button
              onClick={() => {
                if (pending === true) {
                  dispatch(getPendingNurses(searchText));
                } else if (blocked === true) {
                  dispatch(getBlockedNurses(searchText));
                } else if (rejection === true) {
                  dispatch(getRejectedNurses(searchText));
                }
              }}
              className="text-sm bg-lightgreen hover:opacity-70 p-2 rounded-circle text-white"
            >
              {isFetchingPendingNurses ||
              isFetchingRejectedNurses ||
              isFetchingBlockedNurses
                ? "..."
                : "Go"}
            </button>
          </div>
          <p className="text-sm text-white bg-lightgreen py-1 px-3 rounded-xl inline-flex items-center">
            {nurses?.length} {nurses?.length === 1 ? "nurse" : "nurses"}
          </p>
        </div>
      )}
      <div className="bg-white p-4 rounded-xl">
        {isFetchingPendingNurses ||
        isFetchingRejectedNurses ||
        isFetchingBlockedNurses ? (
          <div>
            <Shimmers width="100%" height="60px" />
            <Shimmers width="100%" height="60px" />
            <Shimmers width="100%" height="60px" />
            <Shimmers width="100%" height="60px" />
            <Shimmers width="100%" height="60px" />
          </div>
        ) : (
          <div>
            {nurses.map((nurse, index) => (
              <div
                className={
                  (index !== 0 ? "border-t-4 border-paleblue" : " ") +
                  " flex justify-between items-center py-3"
                }
              >
                <div className="flex items-center space-x-3">
                  <div className="h-12 w-12 flex items-center rounded-3xl">
                    <img
                      className="object-cover h-12 w-12 rounded-md"
                      src={GetValidImage(nurse?.avatar)}
                      alt="Nurse"
                    />
                  </div>
                  <p className="text-sm text-darkblue">{nurse?.full_names}</p>
                </div>
                <button
                  onClick={() => {
                    dispatch(onSetSelectedNurse(nurse));
                  }}
                  className="text-sm rounded-xl bg-lightgreen py-1 px-2 text-white hover:opacity-70"
                >
                  Details
                </button>
              </div>
            ))}
            {nurses?.length === 0 && (
              <p className="text-sm text-gray-800 lg:w-screen">
                no nurses found
              </p>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default PendingNursesList;
