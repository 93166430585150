import actionTypes from "../actions/types";

const initialState = {
  generalStatistics: null,
  incomesStatistics: null,

  patientsStatistics: null,
  isFetchingGeneralStatistics: false,
  isFetchingIncomesStatistics: false,
  isFetchingPatientsStatistics: false,
  errors: null,
  message: null,
};
export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.getGeneralStatistics:
      return {
        ...state,
        isFetchingGeneralStatistics: true,
        errors: null,
      };
    case actionTypes.getGeneralStatisticsSuccess:
      return {
        ...state,
        generalStatistics: action.payload,
        isFetchingGeneralStatistics: false,
      };
    case actionTypes.getGeneralStatisticsFail:
      return {
        ...state,
        errors: action.payload,
        isFetchingGeneralStatistics: false,
      };

    // GET STATISTICS DATA
    case actionTypes.getIncomesStatisticsData:
      return {
        ...state,
        isFetchingGeneralStatistics: true,
        errors: null,
      };
    case actionTypes.getIncomesStatisticsSuccessData:
      return {
        ...state,
        generalStatistics: action.payload,
        isFetchingGeneralStatistics: false,
      };
    case actionTypes.getIncomesStatisticsFailData:
      return {
        ...state,
        errors: action.payload,
        isFetchingGeneralStatistics: false,
      };

    case actionTypes.getIncomesStatistics:
      return {
        ...state,
        isFetchingIncomesStatistics: true,
        errors: null,
      };
    case actionTypes.getIncomesStatisticsSuccess:
      return {
        ...state,
        incomesStatistics: action.payload,
        isFetchingIncomesStatistics: false,
      };
    case actionTypes.getIncomesStatisticsFail:
      return {
        ...state,
        errors: action.payload,
        isFetchingIncomesStatistics: false,
      };

    case actionTypes.getPatientsStatistics:
      return {
        ...state,
        isFetchingPatientsStatistics: true,
        errors: null,
      };
    case actionTypes.getPatientsStatisticsSuccess:
      return {
        ...state,
        patientsStatistics: action.payload,
        isFetchingPatientsStatistics: false,
      };
    case actionTypes.getPatientsStatisticsFail:
      return {
        ...state,
        errors: action.payload,
        isFetchingPatientsStatistics: false,
      };

    default:
      return state;
  }
};
