import React, { useEffect, useState } from "react";
import NurseProfile from "../../components/nurse/NurseProfile";
import NurseDoc from "../../components/nurse/NurseDoc";
import NurseReview from "../../components/nurse/NurseReview";
import NurseAvailability from "../../components/nurse/NurseAvailability";
import { AiTwotoneMessage } from "react-icons/ai";
import { MdOutlineKeyboardBackspace } from "react-icons/md";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { blockNurse, deleteNurse, disapproveNurse } from "../../store/actions";
import { useAlert } from "react-alert";
import { Modal } from "react-bootstrap";
import RejectedNurseAlert from "../../components/pending/nurses/RejectedNurseAlert";
import AlertMessage from "../../components/alert/Alert";
function NurseDetails() {
  const history = useHistory();
  const alert = useAlert();
  const dispatch = useDispatch();
  const { selectedNurse, isDisapprovingNurse, isDeletingNurse } = useSelector(
    ({ Nurses }) => Nurses
  );
  const [showRejectedNurseAlert, setShowRejectedNurseAlert] = useState(false);
  const handleClose = () => {
    setShowRejectedNurseAlert(false);
    history.push("/dashboard/nurses");
    alert.success(<AlertMessage message="Nurse successfully disabled" />);
  };
  useEffect(() => {
    if (!selectedNurse) {
      history.push("/dashboard/nurses");
    }
  }, [selectedNurse]);
  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 px-3.5 sm:p-4">
      <div className="bg-white rounded-xl p-3.5 md:p-10 space-y-6 md:space-y-10 min-h-chartitem">
        <div className="flex items-center space-x-2">
          <MdOutlineKeyboardBackspace
            role="button"
            onClick={() => history.push("/dashboard/nurses")}
            className="text-darkblue text-2xl"
          />{" "}
          <p className="text-base text-darkblue font-semibold">Back</p>
        </div>
        <NurseProfile />

        <div className=" flex justify-center lg:space-y-0 space-x-4 my-4 flex-wrap">
          <button
            onClick={() => {
              dispatch(
                blockNurse(
                  selectedNurse,
                  alert,
                  history,
                  setShowRejectedNurseAlert
                )
              );
            }}
            className="bg-lightgreen hover:opacity-70 px-3 lg:px-10 py-1.5 text-white text-sm rounded-xl"
          >
            {isDisapprovingNurse ? "Disabling ..." : "Disable Account"}
          </button>
          {/* <button
            onClick={() => dispatch(deleteNurse(selectedNurse, history, alert))}
            className="bg-lightgreen hover:opacity-70 px-3 lg:px-10 py-1.5 text-white text-sm rounded-xl"
          >
            {isDeletingNurse ? "Deleting ..." : "Delete Account"}
          </button> */}
          {/* <button className="bg-lightgreen hover:opacity-70 py-1 px-1.5 text-white text-sm rounded-xl">
            <AiTwotoneMessage />
          </button> */}
        </div>
      </div>
      <div className="space-y-6 md:space-y-10">
        <NurseDoc />

        {/* <NurseAvailability /> */}
        <NurseReview />
      </div>
      <Modal show={showRejectedNurseAlert} onHide={handleClose}>
        <RejectedNurseAlert
          handleClose={handleClose}
          alert={alert}
          selectedNurse={selectedNurse}
        />
      </Modal>
    </div>
  );
}

export default NurseDetails;
