import React, { useState } from "react";
import { useSelector } from "react-redux";
import Shimmers from "../../components/loaders/Shimmers";
import NurseReport from "../../components/report/NurseReport";
import ReportsList from "../../components/report/ReportsList";

function Reports() {
  const { reports, isFetchingReport, selectedReport } = useSelector(
    ({ Reports }) => Reports
  );
  return (
    <div className="min-h-screen  grid grid-cols-1 lg:grid-cols-3 md:gap-4">
      <ReportsList />
      {isFetchingReport ? (
        <div className="min-h-carditem mx-3.5 md:mx-10 mt-20 mb-6 col-span-2">
          <Shimmers height="80vh" width="100%" />
        </div>
      ) : (
        <NurseReport />
      )}
    </div>
  );
}

export default Reports;
