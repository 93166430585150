import actionTypes from "../actions/types";

const initialState = {
  nurses: [],
  pendingNurses: [],
  rejectedNurses: [],
  blockedNurses: [],
  selectedNurse: null,
  isFetchingNurses: false,
  isFetchingPendingNurses: false,
  isFetchingRejectedNurses: false,
  isFetchingBlockedNurses: false,
  isApprovingNurse: false,
  isDisapprovingNurse: false,
  isBlockingNurse: false,
  isOverriddingNurseStatus: false,
  isDeletingNurse: false,
  errors: null,
  message: null,
};
export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.getNurses:
      return {
        ...state,
        isFetchingNurses: true,
        errors: null,
      };
    case actionTypes.getNursesSuccess:
      return {
        ...state,
        nurses: action.payload,
        isFetchingNurses: false,
      };
    case actionTypes.getNursesFail:
      return {
        ...state,
        errors: action.payload,
        isFetchingNurses: false,
      };
    case actionTypes.getPendingNurses:
      return {
        ...state,
        isFetchingPendingNurses: true,
        errors: null,
      };
    case actionTypes.getPendingNursesSuccess:
      return {
        ...state,
        pendingNurses: action.payload,
        isFetchingPendingNurses: false,
      };
    case actionTypes.getPendingNursesFail:
      return {
        ...state,
        errors: action.payload,
        isFetchingPendingNurses: false,
      };

    case actionTypes.getRejectedNurses:
      return {
        ...state,
        isFetchingRejectedNurses: true,
        errors: null,
      };
    case actionTypes.getRejectedNursesSuccess:
      return {
        ...state,
        rejectedNurses: action.payload,
        isFetchingRejectedNurses: false,
      };
    case actionTypes.getRejectedNursesFail:
      return {
        ...state,
        errors: action.payload,
        isFetchingRejectedNurses: false,
      };

    case actionTypes.getBlockedNurses:
      return {
        ...state,
        isFetchingBlockedNurses: true,
        errors: null,
      };
    case actionTypes.getBlockedNursesSuccess:
      return {
        ...state,
        blockedNurses: action.payload,
        isFetchingBlockedNurses: false,
      };
    case actionTypes.getBlockedNursesFail:
      return {
        ...state,
        errors: action.payload,
        isFetchingBlockedNurses: false,
      };

    case actionTypes.approveNurse:
      return {
        ...state,
        isApprovingNurse: true,
        errors: null,
        message: null,
      };
    case actionTypes.approveNurseSuccess:
      return {
        ...state,
        message: action.payload,
        isApprovingNurse: false,
      };
    case actionTypes.approveNurseFail:
      return {
        ...state,
        errors: action.payload,
        isApprovingNurse: false,
      };

    case actionTypes.disapproveNurse:
      return {
        ...state,
        isDisapprovingNurse: true,
        errors: null,
        message: null,
      };
    case actionTypes.disapproveNurseSuccess:
      return {
        ...state,
        message: action.payload,
        isDisapprovingNurse: false,
      };
    case actionTypes.disapproveNurseFail:
      return {
        ...state,
        errors: action.payload,
        isDisapprovingNurse: false,
      };

    case actionTypes.blockNurse:
      return {
        ...state,
        isBlockingNurse: true,
        errors: null,
        message: null,
      };
    case actionTypes.blockNurseSuccess:
      return {
        ...state,
        message: action.payload,
        isBlockingNurse: false,
      };
    case actionTypes.blockNurseFail:
      return {
        ...state,
        errors: action.payload,
        isBlockingNurse: false,
      };

    case actionTypes.overrideNurseStatus:
      return {
        ...state,
        isOverriddingNurseStatus: true,
        errors: null,
        message: null,
      };
    case actionTypes.overrideNurseStatusSuccess:
      return {
        ...state,
        message: action.payload,
        isOverriddingNurseStatus: false,
      };
    case actionTypes.overrideNurseStatusFail:
      return {
        ...state,
        errors: action.payload,
        isOverriddingNurseStatus: false,
      };

    case actionTypes.deleteNurse:
      return {
        ...state,
        isDeletingNurse: true,
        errors: null,
        message: null,
      };
    case actionTypes.deleteNurseSuccess:
      return {
        ...state,
        message: action.payload,
        isDeletingNurse: false,
      };
    case actionTypes.deleteNurseFail:
      return {
        ...state,
        errors: action.payload,
        isDeletingNurse: false,
      };
    case actionTypes.setSelectedNurse:
      return {
        ...state,
        selectedNurse: action.payload,
      };

    default:
      return state;
  }
};
