import { axiosInstance } from "./config";
//Aunthentication
export const registerRequest = (data) => axiosInstance.post("/nurse", data);
export const loginRequest = (data) => axiosInstance.put("/auth/login", data);
export const getUserRequest = () => axiosInstance.get("/account");
export const updateUserRequest = (data) =>
  axiosInstance.post("/nurse/update", data);
//Profile
export const createProfileRequest = (data) =>
  axiosInstance.post("/admin/contact-info", data);
export const fetchProfileRequest = () => axiosInstance.get("/nurse/profile");
export const updateProfileRequest = (data) =>
  axiosInstance.post("/nurse/profile/update", data);
export const getAdminContactRequest = () =>
  axiosInstance.get("/admin/contact-info");
// export const createAvailabilityRequest = (data, subscription_plan_id) =>
//   axiosInstance.post(
//     "/nurse/availability?subscription_plan_id=" + subscription_plan_id,
//     data
//   );
// export const fetchAvailabilityRequest = () =>
//   axiosInstance.get("/nurse/availability");
// export const updateAvailabilityRequest = (data) =>
//   axiosInstance.put("/nurse/availability", data);
//statistics
export const fetchStatisticsRequest = () => axiosInstance.get("v1/statistics");
//Nurses
// export const fetchNursesRequest = () => axiosInstance.get("/admin/nurse");

// export const fetchNursesRequest = (searchTxt) =>
//   axiosInstance.get("/admin/nurse?search=" + (searchTxt ?? " "));
export const fetchNursesRequest = (
  searchTxt,
  rating,
  location,
  gender,
  from,
  to,
  status
) =>
  axiosInstance.get(
    searchTxt || rating || location || gender || from || to || status
      ? "/admin/nurse?rating=" +
          (rating ?? "") +
          "&search=" +
          (searchTxt ?? "") +
          "&location=" +
          (location ?? "") +
          "&gender=" +
          (gender ?? "") +
          "&from=" +
          (from ?? "") +
          (to ? "&to=" + to : "") +
          "&status=" +
          (status ?? "")
      : "/admin/nurse"
  );

export const fetchPendingNursesRequest = (searchTxt) =>
  axiosInstance.get("/admin/nurse/pending?search=" + (searchTxt ?? " "));
export const fetchRejectedNursesRequest = (searchTxt) =>
  axiosInstance.get("/admin/nurse/rejected?search=" + (searchTxt ?? " "));
export const fetchBlockedNursesRequest = (searchTxt) =>
  axiosInstance.get("/admin/nurse/blocked?search=" + (searchTxt ?? " "));

export const approveNurseRequest = (nurse_Id) =>
  axiosInstance.get("/admin/nurse/" + nurse_Id + "/approve");
export const disapproveNurseRequest = (nurse_Id) =>
  axiosInstance.get("/admin/nurse/" + nurse_Id + "/reject");
export const blockNurseRequest = (nurse_Id) =>
  axiosInstance.get("/admin/nurse/" + nurse_Id + "/block");
export const overrideNurseStatusRequest = (data) =>
  axiosInstance.post("/admin/nurse/override-status", data);
export const deleteNurseRequest = (nurse_id) =>
  axiosInstance.delete("/admin/nurse/" + nurse_id);
//Patients
// export const fetchPatientsRequest = (searchTxt) =>
//   axiosInstance.get(
//     "/admin/patient" + (searchTxt ? "?search=" + searchTxt + "" : "")
//   );
export const fetchPatientsRequest = (searchTxt, from, to) =>
  axiosInstance.get(
    searchTxt
      ? "/admin/patient?search=" +
          (searchTxt ?? "") +
          (from ? "&date_from=" + from : "") +
          (to ? "&date_to=" + to : "")
      : "/admin/patient" +
          (from ? "?date_from=" + from : "") +
          (to ? "&date_to=" + to : "")
  );

export const fetchPendingPatientsRequestsForNurse = () =>
  axiosInstance.get("/admin/nurse-requests");
//Reports
export const fetchReportsRequest = (nameSeachTxt, selectedDate) =>
  axiosInstance.get(
    "/admin/nurse/report?search=" +
      (nameSeachTxt ?? "") +
      (selectedDate ? "&date=" + selectedDate : "")
  );
export const filterReportsByDateRequest = (searchTxt) =>
  axiosInstance.get("/admin/nurse/report?search=" + searchTxt);
export const markReportReadRequest = (report_id) =>
  axiosInstance.get("admin/nurse/report/" + report_id + "/mark-read");

//Subscription Plans
export const createSubscriptionRequest = (data) =>
  axiosInstance.put("/plan", data);

export const updateSubscriptionRequest = (plan_Id, data) =>
  axiosInstance.put("/plan/" + plan_Id + "/update", data);
export const deleteSubscriptionRequest = (plan_Id) =>
  axiosInstance.delete("/plan/" + plan_Id);
export const fetchSubscriptionsRequest = () => axiosInstance.get("/plan");

//Income/Payments
export const fetchIncomesRequest = (searchText, dateFrom, dateTo) =>
  axiosInstance.get(
    "/transactions?search=" +
      (searchText ?? "") +
      (dateFrom ? "&date_to=" + dateFrom : "") +
      (dateTo ? "&date_from=" + dateTo : "")
  );
export const fetchLatestSubscriptionsRequest = () =>
  axiosInstance.get("/transactions/latestSubscriptions");

//
//Statistics
export const getGeneralStatisticsDataRequest = () =>
  axiosInstance.get("/statistics");
export const getIncomesStatisticsRequest = (dateSelected) =>
  axiosInstance.get("/statistics/total-income?date=" + dateSelected);
export const getPatientsStatisticsRequest = (dateSelected) =>
  axiosInstance.get("/statistics/total-patients?date=" + dateSelected);
//charts
