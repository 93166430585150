import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { GetFileLink } from "../../utils/image";

function NurseDoc() {
  const { selectedNurse } = useSelector(({ Nurses }) => Nurses);
  const { nurseId } = useParams();
  return nurseId ? (
    <div className="bg-white rounded-xl p-6 md:p-10">
      {" "}
      <h5 className="text-xl font-bold text-darkblue text-center">
        Nurse Legal Documents
      </h5>
      <div className="sm:flex justify-center sm:space-x-4 space-y-4 sm:space-y-0 my-4 justify-items-center">
        <div className="flex flex-col items-center space-y-3">
          <a
            href={GetFileLink(selectedNurse?.nurse_profile?.nursing_degree)}
            target="_blank"
            rel="noreferrer"
            className="flex flex-col items-center bg-paleblue p-6 rounded-xl hover:no-underline"
          >
            <svg
              width="38"
              height="48"
              viewBox="0 0 38 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M27.7476 20.9039C27.8709 20.731 27.9189 20.6201 27.938 20.5605C27.8362 20.5067 27.7013 20.3975 26.9656 20.3975C26.5476 20.3975 26.0229 20.4157 25.4668 20.4868C26.1413 21.0057 26.306 21.2681 26.7471 21.2681C26.9399 21.2689 27.4927 21.2606 27.7476 20.9039Z"
                fill="#34BEB9"
              />
              <path
                d="M11.9953 27.0074C12.1187 26.9685 12.8387 26.64 14.1802 24.1638C12.4108 25.1577 11.6908 25.9746 11.6395 26.4347C11.6304 26.5109 11.608 26.7111 11.9953 27.0074Z"
                fill="#34BEB9"
              />
              <path
                d="M37.6207 32.2759V11.568C37.6207 10.9341 37.5446 10.4648 37.1655 10.085L27.5349 0.455172C27.2461 0.166345 26.8455 0 26.4359 0H2.41768C1.43616 0 0.379333 0.758069 0.379333 2.42152V32.2759H37.6207ZM26.0345 2.80634C26.0345 2.42731 26.4922 2.23779 26.7603 2.50593L35.1148 10.8604C35.3829 11.1286 35.1934 11.5862 34.8144 11.5862H26.0345V2.80634ZM10.2541 26.28C10.4047 24.9327 12.0706 23.5225 15.2072 22.0858C16.4519 19.3581 17.6362 15.9972 18.3421 13.1892C17.5162 11.3917 16.7134 9.05959 17.2985 7.69159C17.5037 7.21241 17.7595 6.84497 18.237 6.68607C18.4257 6.62317 18.9024 6.54372 19.0778 6.54372C19.4949 6.54372 19.8615 7.08083 20.1214 7.41186C20.3655 7.72303 20.9192 8.38262 19.8127 13.0411C20.9283 15.3451 22.509 17.6921 24.0235 19.2993C25.1084 19.1032 26.042 19.003 26.8025 19.003C28.0985 19.003 28.8839 19.3051 29.2042 19.9274C29.469 20.4422 29.3606 21.0439 28.8814 21.715C28.4204 22.3597 27.7848 22.7007 27.0442 22.7007C26.0378 22.7007 24.8659 22.0651 23.5592 20.8097C21.2113 21.3004 18.4687 22.176 16.2533 23.1451C15.5614 24.6132 14.8985 25.7959 14.2811 26.6632C13.4345 27.8532 12.7029 28.4069 11.9788 28.4069C11.6908 28.4069 11.4144 28.3134 11.1785 28.1371C10.3153 27.4891 10.1995 26.7699 10.2541 26.28Z"
                fill="#34BEB9"
              />
              <path
                d="M12.5631 37.9514C12.4456 37.7892 12.2817 37.6535 12.0707 37.5442C11.8596 37.435 11.5799 37.3804 11.2331 37.3804H10.2152V40.6841H11.4599C11.6254 40.6841 11.7893 40.656 11.9523 40.5989C12.1145 40.5426 12.2635 40.4499 12.3992 40.3216C12.5349 40.1933 12.6442 40.0146 12.7269 39.7845C12.8097 39.5544 12.8511 39.2697 12.8511 38.9304C12.8511 38.7947 12.832 38.6375 12.7948 38.4612C12.7567 38.2841 12.6798 38.1144 12.5631 37.9514Z"
                fill="#34BEB9"
              />
              <path
                d="M19.2839 15.0405C18.6913 17.088 17.9109 19.2985 17.0709 21.3004C18.8006 20.6292 20.6809 20.0433 22.4469 19.6287C21.3297 18.331 20.2133 16.7106 19.2839 15.0405Z"
                fill="#34BEB9"
              />
              <path
                d="M0.379333 33.9312V46.3449C0.379333 47.18 1.38899 48.0001 2.41768 48.0001H35.5824C36.6111 48.0001 37.6207 47.18 37.6207 46.3449V33.9312H0.379333ZM13.9484 40.0793C13.8053 40.4227 13.605 40.7116 13.3484 40.9449C13.0919 41.1783 12.7824 41.3596 12.4207 41.4878C12.0591 41.6161 11.6585 41.6798 11.2215 41.6798H10.2144V44.6898H8.8563V36.351H11.2546C11.6089 36.351 11.9597 36.4073 12.3065 36.5207C12.6533 36.6341 12.9644 36.8037 13.24 37.0296C13.5156 37.2556 13.7382 37.5295 13.9079 37.8498C14.0775 38.17 14.1628 38.53 14.1628 38.9306C14.1636 39.3535 14.0916 39.7358 13.9484 40.0793ZM22.2706 42.0481C22.1233 42.5347 21.9371 42.9419 21.7104 43.2705C21.4836 43.599 21.2295 43.8572 20.9465 44.0459C20.6635 44.2346 20.3904 44.3753 20.1264 44.4705C19.8624 44.5648 19.6207 44.6252 19.4022 44.6517C19.1837 44.6765 19.0215 44.6898 18.9156 44.6898H15.7592V36.351H18.2709C18.9727 36.351 19.5893 36.4627 20.1206 36.6845C20.6519 36.9063 21.0938 37.2034 21.4447 37.5725C21.7956 37.9416 22.0571 38.3629 22.2309 38.8338C22.4039 39.3047 22.4908 39.7896 22.4908 40.2878C22.4916 40.9747 22.418 41.5615 22.2706 42.0481ZM29.6759 37.3805H25.84V40.0056H29.325V40.9334H25.84V44.6898H24.4596V36.351H29.6759V37.3805Z"
                fill="#34BEB9"
              />
              <path
                d="M20.4607 38.3024C20.2232 38.0268 19.9004 37.8042 19.4933 37.6345C19.0861 37.4649 18.5581 37.3796 17.9092 37.3796H17.1172V43.6933H18.4637C19.384 43.6933 20.0477 43.3995 20.4549 42.8111C20.8621 42.2227 21.0657 41.3703 21.0657 40.2538C21.0657 39.9071 21.0243 39.5636 20.9415 39.2243C20.8579 38.885 20.6982 38.578 20.4607 38.3024Z"
                fill="#34BEB9"
              />
              <path
                d="M18.7816 8.03746C18.7005 8.06477 17.6809 9.49153 18.861 10.699C19.6464 8.94863 18.8171 8.02588 18.7816 8.03746Z"
                fill="#34BEB9"
              />
            </svg>
          </a>{" "}
          <p className="text-darkblue font-semibold text-sm">
            bachelors degree
          </p>
        </div>
        <div className="flex flex-col items-center space-y-3">
          <a
            href={GetFileLink(selectedNurse?.nurse_profile?.national_id)}
            target="_blank"
            rel="noreferrer"
            className="flex flex-col items-center bg-paleblue p-6 rounded-xl hover:no-underline"
          >
            <svg
              width="38"
              height="48"
              viewBox="0 0 38 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M27.7476 20.9039C27.8709 20.731 27.9189 20.6201 27.938 20.5605C27.8362 20.5067 27.7013 20.3975 26.9656 20.3975C26.5476 20.3975 26.0229 20.4157 25.4668 20.4868C26.1413 21.0057 26.306 21.2681 26.7471 21.2681C26.9399 21.2689 27.4927 21.2606 27.7476 20.9039Z"
                fill="#34BEB9"
              />
              <path
                d="M11.9953 27.0074C12.1187 26.9685 12.8387 26.64 14.1802 24.1638C12.4108 25.1577 11.6908 25.9746 11.6395 26.4347C11.6304 26.5109 11.608 26.7111 11.9953 27.0074Z"
                fill="#34BEB9"
              />
              <path
                d="M37.6207 32.2759V11.568C37.6207 10.9341 37.5446 10.4648 37.1655 10.085L27.5349 0.455172C27.2461 0.166345 26.8455 0 26.4359 0H2.41768C1.43616 0 0.379333 0.758069 0.379333 2.42152V32.2759H37.6207ZM26.0345 2.80634C26.0345 2.42731 26.4922 2.23779 26.7603 2.50593L35.1148 10.8604C35.3829 11.1286 35.1934 11.5862 34.8144 11.5862H26.0345V2.80634ZM10.2541 26.28C10.4047 24.9327 12.0706 23.5225 15.2072 22.0858C16.4519 19.3581 17.6362 15.9972 18.3421 13.1892C17.5162 11.3917 16.7134 9.05959 17.2985 7.69159C17.5037 7.21241 17.7595 6.84497 18.237 6.68607C18.4257 6.62317 18.9024 6.54372 19.0778 6.54372C19.4949 6.54372 19.8615 7.08083 20.1214 7.41186C20.3655 7.72303 20.9192 8.38262 19.8127 13.0411C20.9283 15.3451 22.509 17.6921 24.0235 19.2993C25.1084 19.1032 26.042 19.003 26.8025 19.003C28.0985 19.003 28.8839 19.3051 29.2042 19.9274C29.469 20.4422 29.3606 21.0439 28.8814 21.715C28.4204 22.3597 27.7848 22.7007 27.0442 22.7007C26.0378 22.7007 24.8659 22.0651 23.5592 20.8097C21.2113 21.3004 18.4687 22.176 16.2533 23.1451C15.5614 24.6132 14.8985 25.7959 14.2811 26.6632C13.4345 27.8532 12.7029 28.4069 11.9788 28.4069C11.6908 28.4069 11.4144 28.3134 11.1785 28.1371C10.3153 27.4891 10.1995 26.7699 10.2541 26.28Z"
                fill="#34BEB9"
              />
              <path
                d="M12.5631 37.9514C12.4456 37.7892 12.2817 37.6535 12.0707 37.5442C11.8596 37.435 11.5799 37.3804 11.2331 37.3804H10.2152V40.6841H11.4599C11.6254 40.6841 11.7893 40.656 11.9523 40.5989C12.1145 40.5426 12.2635 40.4499 12.3992 40.3216C12.5349 40.1933 12.6442 40.0146 12.7269 39.7845C12.8097 39.5544 12.8511 39.2697 12.8511 38.9304C12.8511 38.7947 12.832 38.6375 12.7948 38.4612C12.7567 38.2841 12.6798 38.1144 12.5631 37.9514Z"
                fill="#34BEB9"
              />
              <path
                d="M19.2839 15.0405C18.6913 17.088 17.9109 19.2985 17.0709 21.3004C18.8006 20.6292 20.6809 20.0433 22.4469 19.6287C21.3297 18.331 20.2133 16.7106 19.2839 15.0405Z"
                fill="#34BEB9"
              />
              <path
                d="M0.379333 33.9312V46.3449C0.379333 47.18 1.38899 48.0001 2.41768 48.0001H35.5824C36.6111 48.0001 37.6207 47.18 37.6207 46.3449V33.9312H0.379333ZM13.9484 40.0793C13.8053 40.4227 13.605 40.7116 13.3484 40.9449C13.0919 41.1783 12.7824 41.3596 12.4207 41.4878C12.0591 41.6161 11.6585 41.6798 11.2215 41.6798H10.2144V44.6898H8.8563V36.351H11.2546C11.6089 36.351 11.9597 36.4073 12.3065 36.5207C12.6533 36.6341 12.9644 36.8037 13.24 37.0296C13.5156 37.2556 13.7382 37.5295 13.9079 37.8498C14.0775 38.17 14.1628 38.53 14.1628 38.9306C14.1636 39.3535 14.0916 39.7358 13.9484 40.0793ZM22.2706 42.0481C22.1233 42.5347 21.9371 42.9419 21.7104 43.2705C21.4836 43.599 21.2295 43.8572 20.9465 44.0459C20.6635 44.2346 20.3904 44.3753 20.1264 44.4705C19.8624 44.5648 19.6207 44.6252 19.4022 44.6517C19.1837 44.6765 19.0215 44.6898 18.9156 44.6898H15.7592V36.351H18.2709C18.9727 36.351 19.5893 36.4627 20.1206 36.6845C20.6519 36.9063 21.0938 37.2034 21.4447 37.5725C21.7956 37.9416 22.0571 38.3629 22.2309 38.8338C22.4039 39.3047 22.4908 39.7896 22.4908 40.2878C22.4916 40.9747 22.418 41.5615 22.2706 42.0481ZM29.6759 37.3805H25.84V40.0056H29.325V40.9334H25.84V44.6898H24.4596V36.351H29.6759V37.3805Z"
                fill="#34BEB9"
              />
              <path
                d="M20.4607 38.3024C20.2232 38.0268 19.9004 37.8042 19.4933 37.6345C19.0861 37.4649 18.5581 37.3796 17.9092 37.3796H17.1172V43.6933H18.4637C19.384 43.6933 20.0477 43.3995 20.4549 42.8111C20.8621 42.2227 21.0657 41.3703 21.0657 40.2538C21.0657 39.9071 21.0243 39.5636 20.9415 39.2243C20.8579 38.885 20.6982 38.578 20.4607 38.3024Z"
                fill="#34BEB9"
              />
              <path
                d="M18.7816 8.03746C18.7005 8.06477 17.6809 9.49153 18.861 10.699C19.6464 8.94863 18.8171 8.02588 18.7816 8.03746Z"
                fill="#34BEB9"
              />
            </svg>
          </a>
          <p className="text-darkblue font-semibold text-sm">Nationalidcard</p>
        </div>
        <div className="flex flex-col items-center space-y-3">
          <a
            href={GetFileLink(selectedNurse?.nurse_profile?.criminal_record)}
            target="_blank"
            rel="noreferrer"
            className="flex flex-col items-center bg-paleblue p-6 rounded-xl hover:no-underline"
          >
            <svg
              width="38"
              height="48"
              viewBox="0 0 38 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M27.7476 20.9039C27.8709 20.731 27.9189 20.6201 27.938 20.5605C27.8362 20.5067 27.7013 20.3975 26.9656 20.3975C26.5476 20.3975 26.0229 20.4157 25.4668 20.4868C26.1413 21.0057 26.306 21.2681 26.7471 21.2681C26.9399 21.2689 27.4927 21.2606 27.7476 20.9039Z"
                fill="#34BEB9"
              />
              <path
                d="M11.9953 27.0074C12.1187 26.9685 12.8387 26.64 14.1802 24.1638C12.4108 25.1577 11.6908 25.9746 11.6395 26.4347C11.6304 26.5109 11.608 26.7111 11.9953 27.0074Z"
                fill="#34BEB9"
              />
              <path
                d="M37.6207 32.2759V11.568C37.6207 10.9341 37.5446 10.4648 37.1655 10.085L27.5349 0.455172C27.2461 0.166345 26.8455 0 26.4359 0H2.41768C1.43616 0 0.379333 0.758069 0.379333 2.42152V32.2759H37.6207ZM26.0345 2.80634C26.0345 2.42731 26.4922 2.23779 26.7603 2.50593L35.1148 10.8604C35.3829 11.1286 35.1934 11.5862 34.8144 11.5862H26.0345V2.80634ZM10.2541 26.28C10.4047 24.9327 12.0706 23.5225 15.2072 22.0858C16.4519 19.3581 17.6362 15.9972 18.3421 13.1892C17.5162 11.3917 16.7134 9.05959 17.2985 7.69159C17.5037 7.21241 17.7595 6.84497 18.237 6.68607C18.4257 6.62317 18.9024 6.54372 19.0778 6.54372C19.4949 6.54372 19.8615 7.08083 20.1214 7.41186C20.3655 7.72303 20.9192 8.38262 19.8127 13.0411C20.9283 15.3451 22.509 17.6921 24.0235 19.2993C25.1084 19.1032 26.042 19.003 26.8025 19.003C28.0985 19.003 28.8839 19.3051 29.2042 19.9274C29.469 20.4422 29.3606 21.0439 28.8814 21.715C28.4204 22.3597 27.7848 22.7007 27.0442 22.7007C26.0378 22.7007 24.8659 22.0651 23.5592 20.8097C21.2113 21.3004 18.4687 22.176 16.2533 23.1451C15.5614 24.6132 14.8985 25.7959 14.2811 26.6632C13.4345 27.8532 12.7029 28.4069 11.9788 28.4069C11.6908 28.4069 11.4144 28.3134 11.1785 28.1371C10.3153 27.4891 10.1995 26.7699 10.2541 26.28Z"
                fill="#34BEB9"
              />
              <path
                d="M12.5631 37.9514C12.4456 37.7892 12.2817 37.6535 12.0707 37.5442C11.8596 37.435 11.5799 37.3804 11.2331 37.3804H10.2152V40.6841H11.4599C11.6254 40.6841 11.7893 40.656 11.9523 40.5989C12.1145 40.5426 12.2635 40.4499 12.3992 40.3216C12.5349 40.1933 12.6442 40.0146 12.7269 39.7845C12.8097 39.5544 12.8511 39.2697 12.8511 38.9304C12.8511 38.7947 12.832 38.6375 12.7948 38.4612C12.7567 38.2841 12.6798 38.1144 12.5631 37.9514Z"
                fill="#34BEB9"
              />
              <path
                d="M19.2839 15.0405C18.6913 17.088 17.9109 19.2985 17.0709 21.3004C18.8006 20.6292 20.6809 20.0433 22.4469 19.6287C21.3297 18.331 20.2133 16.7106 19.2839 15.0405Z"
                fill="#34BEB9"
              />
              <path
                d="M0.379333 33.9312V46.3449C0.379333 47.18 1.38899 48.0001 2.41768 48.0001H35.5824C36.6111 48.0001 37.6207 47.18 37.6207 46.3449V33.9312H0.379333ZM13.9484 40.0793C13.8053 40.4227 13.605 40.7116 13.3484 40.9449C13.0919 41.1783 12.7824 41.3596 12.4207 41.4878C12.0591 41.6161 11.6585 41.6798 11.2215 41.6798H10.2144V44.6898H8.8563V36.351H11.2546C11.6089 36.351 11.9597 36.4073 12.3065 36.5207C12.6533 36.6341 12.9644 36.8037 13.24 37.0296C13.5156 37.2556 13.7382 37.5295 13.9079 37.8498C14.0775 38.17 14.1628 38.53 14.1628 38.9306C14.1636 39.3535 14.0916 39.7358 13.9484 40.0793ZM22.2706 42.0481C22.1233 42.5347 21.9371 42.9419 21.7104 43.2705C21.4836 43.599 21.2295 43.8572 20.9465 44.0459C20.6635 44.2346 20.3904 44.3753 20.1264 44.4705C19.8624 44.5648 19.6207 44.6252 19.4022 44.6517C19.1837 44.6765 19.0215 44.6898 18.9156 44.6898H15.7592V36.351H18.2709C18.9727 36.351 19.5893 36.4627 20.1206 36.6845C20.6519 36.9063 21.0938 37.2034 21.4447 37.5725C21.7956 37.9416 22.0571 38.3629 22.2309 38.8338C22.4039 39.3047 22.4908 39.7896 22.4908 40.2878C22.4916 40.9747 22.418 41.5615 22.2706 42.0481ZM29.6759 37.3805H25.84V40.0056H29.325V40.9334H25.84V44.6898H24.4596V36.351H29.6759V37.3805Z"
                fill="#34BEB9"
              />
              <path
                d="M20.4607 38.3024C20.2232 38.0268 19.9004 37.8042 19.4933 37.6345C19.0861 37.4649 18.5581 37.3796 17.9092 37.3796H17.1172V43.6933H18.4637C19.384 43.6933 20.0477 43.3995 20.4549 42.8111C20.8621 42.2227 21.0657 41.3703 21.0657 40.2538C21.0657 39.9071 21.0243 39.5636 20.9415 39.2243C20.8579 38.885 20.6982 38.578 20.4607 38.3024Z"
                fill="#34BEB9"
              />
              <path
                d="M18.7816 8.03746C18.7005 8.06477 17.6809 9.49153 18.861 10.699C19.6464 8.94863 18.8171 8.02588 18.7816 8.03746Z"
                fill="#34BEB9"
              />
            </svg>
          </a>
          <p className="text-darkblue font-semibold text-sm">Criminal Record</p>
        </div>
      </div>
    </div>
  ) : (
    <div className="bg-white rounded-xl ">
      {" "}
      <h5 className="text-xl font-bold text-darkblue ">
        Nurse Legal Documents
      </h5>
      <div className="sm:flex justify-center sm:space-x-4 space-y-4 sm:space-y-0 my-4 justify-items-center">
        <a
          href={GetFileLink(selectedNurse?.nurse_profile?.nursing_degree)}
          target="_blank"
          rel="noreferrer"
          className="flex flex-col items-center space-y-3 bg-paleblue p-6 rounded-xl hover:no-underline"
        >
          <svg
            width="38"
            height="48"
            viewBox="0 0 38 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M27.7476 20.9039C27.8709 20.731 27.9189 20.6201 27.938 20.5605C27.8362 20.5067 27.7013 20.3975 26.9656 20.3975C26.5476 20.3975 26.0229 20.4157 25.4668 20.4868C26.1413 21.0057 26.306 21.2681 26.7471 21.2681C26.9399 21.2689 27.4927 21.2606 27.7476 20.9039Z"
              fill="#34BEB9"
            />
            <path
              d="M11.9953 27.0074C12.1187 26.9685 12.8387 26.64 14.1802 24.1638C12.4108 25.1577 11.6908 25.9746 11.6395 26.4347C11.6304 26.5109 11.608 26.7111 11.9953 27.0074Z"
              fill="#34BEB9"
            />
            <path
              d="M37.6207 32.2759V11.568C37.6207 10.9341 37.5446 10.4648 37.1655 10.085L27.5349 0.455172C27.2461 0.166345 26.8455 0 26.4359 0H2.41768C1.43616 0 0.379333 0.758069 0.379333 2.42152V32.2759H37.6207ZM26.0345 2.80634C26.0345 2.42731 26.4922 2.23779 26.7603 2.50593L35.1148 10.8604C35.3829 11.1286 35.1934 11.5862 34.8144 11.5862H26.0345V2.80634ZM10.2541 26.28C10.4047 24.9327 12.0706 23.5225 15.2072 22.0858C16.4519 19.3581 17.6362 15.9972 18.3421 13.1892C17.5162 11.3917 16.7134 9.05959 17.2985 7.69159C17.5037 7.21241 17.7595 6.84497 18.237 6.68607C18.4257 6.62317 18.9024 6.54372 19.0778 6.54372C19.4949 6.54372 19.8615 7.08083 20.1214 7.41186C20.3655 7.72303 20.9192 8.38262 19.8127 13.0411C20.9283 15.3451 22.509 17.6921 24.0235 19.2993C25.1084 19.1032 26.042 19.003 26.8025 19.003C28.0985 19.003 28.8839 19.3051 29.2042 19.9274C29.469 20.4422 29.3606 21.0439 28.8814 21.715C28.4204 22.3597 27.7848 22.7007 27.0442 22.7007C26.0378 22.7007 24.8659 22.0651 23.5592 20.8097C21.2113 21.3004 18.4687 22.176 16.2533 23.1451C15.5614 24.6132 14.8985 25.7959 14.2811 26.6632C13.4345 27.8532 12.7029 28.4069 11.9788 28.4069C11.6908 28.4069 11.4144 28.3134 11.1785 28.1371C10.3153 27.4891 10.1995 26.7699 10.2541 26.28Z"
              fill="#34BEB9"
            />
            <path
              d="M12.5631 37.9514C12.4456 37.7892 12.2817 37.6535 12.0707 37.5442C11.8596 37.435 11.5799 37.3804 11.2331 37.3804H10.2152V40.6841H11.4599C11.6254 40.6841 11.7893 40.656 11.9523 40.5989C12.1145 40.5426 12.2635 40.4499 12.3992 40.3216C12.5349 40.1933 12.6442 40.0146 12.7269 39.7845C12.8097 39.5544 12.8511 39.2697 12.8511 38.9304C12.8511 38.7947 12.832 38.6375 12.7948 38.4612C12.7567 38.2841 12.6798 38.1144 12.5631 37.9514Z"
              fill="#34BEB9"
            />
            <path
              d="M19.2839 15.0405C18.6913 17.088 17.9109 19.2985 17.0709 21.3004C18.8006 20.6292 20.6809 20.0433 22.4469 19.6287C21.3297 18.331 20.2133 16.7106 19.2839 15.0405Z"
              fill="#34BEB9"
            />
            <path
              d="M0.379333 33.9312V46.3449C0.379333 47.18 1.38899 48.0001 2.41768 48.0001H35.5824C36.6111 48.0001 37.6207 47.18 37.6207 46.3449V33.9312H0.379333ZM13.9484 40.0793C13.8053 40.4227 13.605 40.7116 13.3484 40.9449C13.0919 41.1783 12.7824 41.3596 12.4207 41.4878C12.0591 41.6161 11.6585 41.6798 11.2215 41.6798H10.2144V44.6898H8.8563V36.351H11.2546C11.6089 36.351 11.9597 36.4073 12.3065 36.5207C12.6533 36.6341 12.9644 36.8037 13.24 37.0296C13.5156 37.2556 13.7382 37.5295 13.9079 37.8498C14.0775 38.17 14.1628 38.53 14.1628 38.9306C14.1636 39.3535 14.0916 39.7358 13.9484 40.0793ZM22.2706 42.0481C22.1233 42.5347 21.9371 42.9419 21.7104 43.2705C21.4836 43.599 21.2295 43.8572 20.9465 44.0459C20.6635 44.2346 20.3904 44.3753 20.1264 44.4705C19.8624 44.5648 19.6207 44.6252 19.4022 44.6517C19.1837 44.6765 19.0215 44.6898 18.9156 44.6898H15.7592V36.351H18.2709C18.9727 36.351 19.5893 36.4627 20.1206 36.6845C20.6519 36.9063 21.0938 37.2034 21.4447 37.5725C21.7956 37.9416 22.0571 38.3629 22.2309 38.8338C22.4039 39.3047 22.4908 39.7896 22.4908 40.2878C22.4916 40.9747 22.418 41.5615 22.2706 42.0481ZM29.6759 37.3805H25.84V40.0056H29.325V40.9334H25.84V44.6898H24.4596V36.351H29.6759V37.3805Z"
              fill="#34BEB9"
            />
            <path
              d="M20.4607 38.3024C20.2232 38.0268 19.9004 37.8042 19.4933 37.6345C19.0861 37.4649 18.5581 37.3796 17.9092 37.3796H17.1172V43.6933H18.4637C19.384 43.6933 20.0477 43.3995 20.4549 42.8111C20.8621 42.2227 21.0657 41.3703 21.0657 40.2538C21.0657 39.9071 21.0243 39.5636 20.9415 39.2243C20.8579 38.885 20.6982 38.578 20.4607 38.3024Z"
              fill="#34BEB9"
            />
            <path
              d="M18.7816 8.03746C18.7005 8.06477 17.6809 9.49153 18.861 10.699C19.6464 8.94863 18.8171 8.02588 18.7816 8.03746Z"
              fill="#34BEB9"
            />
          </svg>
          <p className="text-darkblue font-semibold text-sm">
            bachelors degree
          </p>
        </a>{" "}
        <a
          href={GetFileLink(selectedNurse?.nurse_profile?.national_id)}
          target="_blank"
          rel="noreferrer"
          className="flex flex-col items-center space-y-3 bg-paleblue p-6 rounded-xl hover:no-underline"
        >
          <svg
            width="38"
            height="48"
            viewBox="0 0 38 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M27.7476 20.9039C27.8709 20.731 27.9189 20.6201 27.938 20.5605C27.8362 20.5067 27.7013 20.3975 26.9656 20.3975C26.5476 20.3975 26.0229 20.4157 25.4668 20.4868C26.1413 21.0057 26.306 21.2681 26.7471 21.2681C26.9399 21.2689 27.4927 21.2606 27.7476 20.9039Z"
              fill="#34BEB9"
            />
            <path
              d="M11.9953 27.0074C12.1187 26.9685 12.8387 26.64 14.1802 24.1638C12.4108 25.1577 11.6908 25.9746 11.6395 26.4347C11.6304 26.5109 11.608 26.7111 11.9953 27.0074Z"
              fill="#34BEB9"
            />
            <path
              d="M37.6207 32.2759V11.568C37.6207 10.9341 37.5446 10.4648 37.1655 10.085L27.5349 0.455172C27.2461 0.166345 26.8455 0 26.4359 0H2.41768C1.43616 0 0.379333 0.758069 0.379333 2.42152V32.2759H37.6207ZM26.0345 2.80634C26.0345 2.42731 26.4922 2.23779 26.7603 2.50593L35.1148 10.8604C35.3829 11.1286 35.1934 11.5862 34.8144 11.5862H26.0345V2.80634ZM10.2541 26.28C10.4047 24.9327 12.0706 23.5225 15.2072 22.0858C16.4519 19.3581 17.6362 15.9972 18.3421 13.1892C17.5162 11.3917 16.7134 9.05959 17.2985 7.69159C17.5037 7.21241 17.7595 6.84497 18.237 6.68607C18.4257 6.62317 18.9024 6.54372 19.0778 6.54372C19.4949 6.54372 19.8615 7.08083 20.1214 7.41186C20.3655 7.72303 20.9192 8.38262 19.8127 13.0411C20.9283 15.3451 22.509 17.6921 24.0235 19.2993C25.1084 19.1032 26.042 19.003 26.8025 19.003C28.0985 19.003 28.8839 19.3051 29.2042 19.9274C29.469 20.4422 29.3606 21.0439 28.8814 21.715C28.4204 22.3597 27.7848 22.7007 27.0442 22.7007C26.0378 22.7007 24.8659 22.0651 23.5592 20.8097C21.2113 21.3004 18.4687 22.176 16.2533 23.1451C15.5614 24.6132 14.8985 25.7959 14.2811 26.6632C13.4345 27.8532 12.7029 28.4069 11.9788 28.4069C11.6908 28.4069 11.4144 28.3134 11.1785 28.1371C10.3153 27.4891 10.1995 26.7699 10.2541 26.28Z"
              fill="#34BEB9"
            />
            <path
              d="M12.5631 37.9514C12.4456 37.7892 12.2817 37.6535 12.0707 37.5442C11.8596 37.435 11.5799 37.3804 11.2331 37.3804H10.2152V40.6841H11.4599C11.6254 40.6841 11.7893 40.656 11.9523 40.5989C12.1145 40.5426 12.2635 40.4499 12.3992 40.3216C12.5349 40.1933 12.6442 40.0146 12.7269 39.7845C12.8097 39.5544 12.8511 39.2697 12.8511 38.9304C12.8511 38.7947 12.832 38.6375 12.7948 38.4612C12.7567 38.2841 12.6798 38.1144 12.5631 37.9514Z"
              fill="#34BEB9"
            />
            <path
              d="M19.2839 15.0405C18.6913 17.088 17.9109 19.2985 17.0709 21.3004C18.8006 20.6292 20.6809 20.0433 22.4469 19.6287C21.3297 18.331 20.2133 16.7106 19.2839 15.0405Z"
              fill="#34BEB9"
            />
            <path
              d="M0.379333 33.9312V46.3449C0.379333 47.18 1.38899 48.0001 2.41768 48.0001H35.5824C36.6111 48.0001 37.6207 47.18 37.6207 46.3449V33.9312H0.379333ZM13.9484 40.0793C13.8053 40.4227 13.605 40.7116 13.3484 40.9449C13.0919 41.1783 12.7824 41.3596 12.4207 41.4878C12.0591 41.6161 11.6585 41.6798 11.2215 41.6798H10.2144V44.6898H8.8563V36.351H11.2546C11.6089 36.351 11.9597 36.4073 12.3065 36.5207C12.6533 36.6341 12.9644 36.8037 13.24 37.0296C13.5156 37.2556 13.7382 37.5295 13.9079 37.8498C14.0775 38.17 14.1628 38.53 14.1628 38.9306C14.1636 39.3535 14.0916 39.7358 13.9484 40.0793ZM22.2706 42.0481C22.1233 42.5347 21.9371 42.9419 21.7104 43.2705C21.4836 43.599 21.2295 43.8572 20.9465 44.0459C20.6635 44.2346 20.3904 44.3753 20.1264 44.4705C19.8624 44.5648 19.6207 44.6252 19.4022 44.6517C19.1837 44.6765 19.0215 44.6898 18.9156 44.6898H15.7592V36.351H18.2709C18.9727 36.351 19.5893 36.4627 20.1206 36.6845C20.6519 36.9063 21.0938 37.2034 21.4447 37.5725C21.7956 37.9416 22.0571 38.3629 22.2309 38.8338C22.4039 39.3047 22.4908 39.7896 22.4908 40.2878C22.4916 40.9747 22.418 41.5615 22.2706 42.0481ZM29.6759 37.3805H25.84V40.0056H29.325V40.9334H25.84V44.6898H24.4596V36.351H29.6759V37.3805Z"
              fill="#34BEB9"
            />
            <path
              d="M20.4607 38.3024C20.2232 38.0268 19.9004 37.8042 19.4933 37.6345C19.0861 37.4649 18.5581 37.3796 17.9092 37.3796H17.1172V43.6933H18.4637C19.384 43.6933 20.0477 43.3995 20.4549 42.8111C20.8621 42.2227 21.0657 41.3703 21.0657 40.2538C21.0657 39.9071 21.0243 39.5636 20.9415 39.2243C20.8579 38.885 20.6982 38.578 20.4607 38.3024Z"
              fill="#34BEB9"
            />
            <path
              d="M18.7816 8.03746C18.7005 8.06477 17.6809 9.49153 18.861 10.699C19.6464 8.94863 18.8171 8.02588 18.7816 8.03746Z"
              fill="#34BEB9"
            />
          </svg>
          <p className="text-darkblue font-semibold text-sm">Nationalidcard</p>
        </a>
        <a
          href={GetFileLink(selectedNurse?.nurse_profile?.criminal_record)}
          target="_blank"
          rel="noreferrer"
          className="flex flex-col items-center space-y-3 bg-paleblue p-6 rounded-xl hover:no-underline"
        >
          <svg
            width="38"
            height="48"
            viewBox="0 0 38 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M27.7476 20.9039C27.8709 20.731 27.9189 20.6201 27.938 20.5605C27.8362 20.5067 27.7013 20.3975 26.9656 20.3975C26.5476 20.3975 26.0229 20.4157 25.4668 20.4868C26.1413 21.0057 26.306 21.2681 26.7471 21.2681C26.9399 21.2689 27.4927 21.2606 27.7476 20.9039Z"
              fill="#34BEB9"
            />
            <path
              d="M11.9953 27.0074C12.1187 26.9685 12.8387 26.64 14.1802 24.1638C12.4108 25.1577 11.6908 25.9746 11.6395 26.4347C11.6304 26.5109 11.608 26.7111 11.9953 27.0074Z"
              fill="#34BEB9"
            />
            <path
              d="M37.6207 32.2759V11.568C37.6207 10.9341 37.5446 10.4648 37.1655 10.085L27.5349 0.455172C27.2461 0.166345 26.8455 0 26.4359 0H2.41768C1.43616 0 0.379333 0.758069 0.379333 2.42152V32.2759H37.6207ZM26.0345 2.80634C26.0345 2.42731 26.4922 2.23779 26.7603 2.50593L35.1148 10.8604C35.3829 11.1286 35.1934 11.5862 34.8144 11.5862H26.0345V2.80634ZM10.2541 26.28C10.4047 24.9327 12.0706 23.5225 15.2072 22.0858C16.4519 19.3581 17.6362 15.9972 18.3421 13.1892C17.5162 11.3917 16.7134 9.05959 17.2985 7.69159C17.5037 7.21241 17.7595 6.84497 18.237 6.68607C18.4257 6.62317 18.9024 6.54372 19.0778 6.54372C19.4949 6.54372 19.8615 7.08083 20.1214 7.41186C20.3655 7.72303 20.9192 8.38262 19.8127 13.0411C20.9283 15.3451 22.509 17.6921 24.0235 19.2993C25.1084 19.1032 26.042 19.003 26.8025 19.003C28.0985 19.003 28.8839 19.3051 29.2042 19.9274C29.469 20.4422 29.3606 21.0439 28.8814 21.715C28.4204 22.3597 27.7848 22.7007 27.0442 22.7007C26.0378 22.7007 24.8659 22.0651 23.5592 20.8097C21.2113 21.3004 18.4687 22.176 16.2533 23.1451C15.5614 24.6132 14.8985 25.7959 14.2811 26.6632C13.4345 27.8532 12.7029 28.4069 11.9788 28.4069C11.6908 28.4069 11.4144 28.3134 11.1785 28.1371C10.3153 27.4891 10.1995 26.7699 10.2541 26.28Z"
              fill="#34BEB9"
            />
            <path
              d="M12.5631 37.9514C12.4456 37.7892 12.2817 37.6535 12.0707 37.5442C11.8596 37.435 11.5799 37.3804 11.2331 37.3804H10.2152V40.6841H11.4599C11.6254 40.6841 11.7893 40.656 11.9523 40.5989C12.1145 40.5426 12.2635 40.4499 12.3992 40.3216C12.5349 40.1933 12.6442 40.0146 12.7269 39.7845C12.8097 39.5544 12.8511 39.2697 12.8511 38.9304C12.8511 38.7947 12.832 38.6375 12.7948 38.4612C12.7567 38.2841 12.6798 38.1144 12.5631 37.9514Z"
              fill="#34BEB9"
            />
            <path
              d="M19.2839 15.0405C18.6913 17.088 17.9109 19.2985 17.0709 21.3004C18.8006 20.6292 20.6809 20.0433 22.4469 19.6287C21.3297 18.331 20.2133 16.7106 19.2839 15.0405Z"
              fill="#34BEB9"
            />
            <path
              d="M0.379333 33.9312V46.3449C0.379333 47.18 1.38899 48.0001 2.41768 48.0001H35.5824C36.6111 48.0001 37.6207 47.18 37.6207 46.3449V33.9312H0.379333ZM13.9484 40.0793C13.8053 40.4227 13.605 40.7116 13.3484 40.9449C13.0919 41.1783 12.7824 41.3596 12.4207 41.4878C12.0591 41.6161 11.6585 41.6798 11.2215 41.6798H10.2144V44.6898H8.8563V36.351H11.2546C11.6089 36.351 11.9597 36.4073 12.3065 36.5207C12.6533 36.6341 12.9644 36.8037 13.24 37.0296C13.5156 37.2556 13.7382 37.5295 13.9079 37.8498C14.0775 38.17 14.1628 38.53 14.1628 38.9306C14.1636 39.3535 14.0916 39.7358 13.9484 40.0793ZM22.2706 42.0481C22.1233 42.5347 21.9371 42.9419 21.7104 43.2705C21.4836 43.599 21.2295 43.8572 20.9465 44.0459C20.6635 44.2346 20.3904 44.3753 20.1264 44.4705C19.8624 44.5648 19.6207 44.6252 19.4022 44.6517C19.1837 44.6765 19.0215 44.6898 18.9156 44.6898H15.7592V36.351H18.2709C18.9727 36.351 19.5893 36.4627 20.1206 36.6845C20.6519 36.9063 21.0938 37.2034 21.4447 37.5725C21.7956 37.9416 22.0571 38.3629 22.2309 38.8338C22.4039 39.3047 22.4908 39.7896 22.4908 40.2878C22.4916 40.9747 22.418 41.5615 22.2706 42.0481ZM29.6759 37.3805H25.84V40.0056H29.325V40.9334H25.84V44.6898H24.4596V36.351H29.6759V37.3805Z"
              fill="#34BEB9"
            />
            <path
              d="M20.4607 38.3024C20.2232 38.0268 19.9004 37.8042 19.4933 37.6345C19.0861 37.4649 18.5581 37.3796 17.9092 37.3796H17.1172V43.6933H18.4637C19.384 43.6933 20.0477 43.3995 20.4549 42.8111C20.8621 42.2227 21.0657 41.3703 21.0657 40.2538C21.0657 39.9071 21.0243 39.5636 20.9415 39.2243C20.8579 38.885 20.6982 38.578 20.4607 38.3024Z"
              fill="#34BEB9"
            />
            <path
              d="M18.7816 8.03746C18.7005 8.06477 17.6809 9.49153 18.861 10.699C19.6464 8.94863 18.8171 8.02588 18.7816 8.03746Z"
              fill="#34BEB9"
            />
          </svg>
          <p className="text-darkblue font-semibold text-sm">Criminal Record</p>
        </a>
      </div>
    </div>
  );
}

export default NurseDoc;
