import actionTypes from "../actions/types";

const initialState = {
  charts: [],
  usersList: [],
  newChartNurses: [],
  latestMessages: [],
  isFetchingUsersList: false,
  isSearchingUsersList: false,
  isFetchingLatestMessages: false,
  isFetchingTotalNewCharts: false,
  isFetchingTotalUserUnreadMessages: false,
  isFetchingNewUsers: false,
  isMarkingUnreanMessages: false,
  isMarkingUnreadChart: false,
  isFetching: false,
  isSending: false,
  isUpdating: false,
  errors: null,
  openCharts: false,
  selectedChart: [],
  totalNewCharts: null,
  totalUserUnreadMessages: null,
  selectedNewChartNurse: null,
  selectedUser: null,
  message: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.getCharts:
      return {
        ...state,
        isFetching: true,
        errors: null,
      };
    case actionTypes.getChartsSuccess:
      return {
        ...state,
        charts: action.payload,
        isFetching: false,
      };
    case actionTypes.getChartsFail:
      return {
        ...state,
        isFetching: false,
        errors: action.payload,
      };

    case actionTypes.getNewChartNurses:
      return {
        ...state,
        isFetchingNewUsers: true,
        errors: null,
      };
    case actionTypes.getNewChartNursesSuccess:
      return {
        ...state,
        newChartNurses: action.payload,
        isFetchingNewUsers: false,
      };
    case actionTypes.getNewChartNursesFail:
      return {
        ...state,
        isFetchingNewUsers: false,
        errors: action.payload,
      };

    case actionTypes.sendChart:
      return {
        ...state,
        isSending: true,
        errors: null,
      };
    case actionTypes.sendChartSuccess:
      return {
        ...state,
        isSending: false,
        message: action.payload,
      };
    case actionTypes.sendChartFail:
      return {
        ...state,
        isSending: false,
        errors: action.payload,
      };

    case actionTypes.getUsersList:
      return {
        ...state,
        isFetchingUsersList: true,
        errors: null,
      };
    case actionTypes.getUsersListSuccess:
      return {
        ...state,
        usersList: action.payload,
        isFetchingUsersList: false,
      };
    case actionTypes.getUsersListFail:
      return {
        ...state,
        isFetchingUsersList: false,
        errors: action.payload,
      };

    case actionTypes.searchUsersByName:
      return {
        ...state,
        isSearchingUsersList: true,
        errors: null,
      };
    case actionTypes.searchUsersByNameSuccess:
      return {
        ...state,
        usersList: action.payload,
        isSearchingUsersList: false,
      };
    case actionTypes.searchUsersByNameFail:
      return {
        ...state,
        isSearchingUsersList: false,
        errors: action.payload,
      };

    case actionTypes.getLatestMessages:
      return {
        ...state,
        isFetchingLatestMessages: true,
        errors: null,
      };
    case actionTypes.getLatestMessagesSuccess:
      return {
        ...state,
        latestMessages: action.payload,
        isFetchingLatestMessages: false,
      };
    case actionTypes.getLatestMessagesFail:
      return {
        ...state,
        isFetchingLatestMessages: false,
        errors: action.payload,
      };

    case actionTypes.getTotalNewChart:
      return {
        ...state,
        isFetchingTotalNewCharts: true,
        errors: null,
      };
    case actionTypes.getTotalNewChartSuccess:
      return {
        ...state,
        totalNewCharts: action.payload,
        isFetchingTotalNewCharts: false,
      };
    case actionTypes.getTotalNewChartFail:
      return {
        ...state,
        isFetchingTotalNewCharts: false,
        errors: action.payload,
      };

    case actionTypes.getTotalUserUnreadMessages:
      return {
        ...state,
        isFetchingTotalUserUnreadMessages: true,
        errors: null,
      };
    case actionTypes.getTotalUserUnreadMessagesSuccess:
      return {
        ...state,
        totalUserUnreadMessages: action.payload,
        isFetchingTotalUserUnreadMessages: false,
      };
    case actionTypes.getTotalUserUnreadMessagesFail:
      return {
        ...state,
        isFetchingTotalUserUnreadMessages: false,
        errors: action.payload,
      };

    case actionTypes.markChartRead:
      return {
        ...state,
        isMarkingUnreadChart: true,
        errors: null,
      };
    case actionTypes.markChartReadSuccess:
      return {
        ...state,
        isMarkingUnreadChart: false,
      };
    case actionTypes.markChartReadFail:
      return {
        ...state,
        isMarkingUnreadChart: false,
      };
    case actionTypes.markMessagesRead:
      return {
        ...state,
        isMarkingUnreanMessages: true,
        errors: null,
      };
    case actionTypes.markMessagesReadSuccess:
      return {
        ...state,
        isMarkingUnreanMessages: false,
      };
    case actionTypes.markMessagesReadFail:
      return {
        ...state,
        isMarkingUnreanMessages: false,
      };
    case actionTypes.setOpenCharts:
      return {
        ...state,
        openCharts: action.payload,
      };
    case actionTypes.setSelectedChart:
      return {
        ...state,
        selectedChart: action.payload,
      };
    case actionTypes.setSelectedUser:
      return {
        ...state,
        selectedUser: action.payload,
      };
    case actionTypes.setSelectedNewChartNurse:
      return {
        ...state,
        selectedNewChartNurse: action.payload,
      };
    default:
      return state;
  }
};
