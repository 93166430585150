import React from "react";
import adminVector from "../../assets/images/admin vector.png";

function Banner() {
  return (
    <div className="register bg-lightgreen relative  justify-center items-center hidden md:flex">
      <div className=" flex justify-center items-center ">
        <img
          src={adminVector}
          alt="Admin vector"
          className="object-cover w-11/12"
        />
      </div>
    </div>
  );
}

export default Banner;
