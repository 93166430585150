import React from "react";
import LatestMessages from "../../components/messages/LatestMessages";
import LatestPatients from "../../components/patients/LatestPatients";
import LatestReports from "../../components/report/LatestReports";
import GeneralStatistics from "../../components/statistics/GeneralStatistics";
import IncomeStatistics from "../../components/statistics/IncomeStatistics";

function Home() {
  return (
    <div className="grid grid-cols-1 lg:grid-cols-3 min-h-screen">
      <div className="col-span-2 md:pt-20 pb-6 space-y-6 mx-3.5 sm:mx-10">
        <h2 className="font-black text-darkblue text-2xl ">Dashboard</h2>
        <GeneralStatistics />
        <IncomeStatistics />
        <LatestPatients />
      </div>
      <div className="bg-paleindigo md:pt-20 pb-6 space-y-10 px-3.5 sm:px-10 col-span-2 lg:col-span-1">
        <LatestReports />
        <LatestMessages />
      </div>
    </div>
  );
}

export default Home;
