import React from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
function ReportExport() {
  const { isSendingReport, errors, selectedReport } = useSelector(
    ({ Reports }) => Reports
  );
  return selectedReport ? (
    <div id="report" className="hidden px-10">
      <div className="bg-white rounded-2xl py-6 px-3">
        <h2 className="text-darkblue text-xl font-bold text-center">Report</h2>
        <p className="text-sm text-gray-500 text-center">
          {moment(selectedReport?.created_at).format("MMMM Do YYYY, h:mm:ss a")}
        </p>
      </div>

      <div className="space-y-2 my-1 bg-white rounded-2xl p-4">
        <h5 className="text-darkblue font-bold text-base">Patient’s Name</h5>
        <p className="text-sm text-gray-500 bg-paleblue py-3 rounded-xl px-2">
          Uwayezu Jean de Dieu
        </p>
        <div className="p-4 border-t-15 rounded-b-2xl border-lightgreen bg-paleblue">
          <p className="text-sm text-gray-500 " id="ds">
            {selectedReport?.description}
          </p>
        </div>
        <div className="p-1 border-t-15 rounded-b-2xl border-lightgreen ">
          {/* <p className="text-sm text-gray-500 " id="ds">
                {selectedReport?.description}
              </p> */}
        </div>
        <div className="read-report full-report">
          <ReactQuill
            theme="snow"
            value={selectedReport?.description}
            readOnly
          />
        </div>
      </div>
    </div>
  ) : null;
}

export default ReportExport;
