import React, { useEffect } from "react";
import NurseDoc from "../../components/nurse/NurseDoc";
import NurseProfile from "../../components/nurse/NurseProfile";
import PendingNursesList from "../../components/pending/nurses/PendingNursesList";
import { AiTwotoneMessage } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import {
  approveNurse,
  disapproveNurse,
  getPendingNurses,
  getRejectedNurses,
  onSetSelectedNurse,
} from "../../store/actions";
import Shimmers from "../../components/loaders/Shimmers";
import { useAlert } from "react-alert";
import { useHistory } from "react-router-dom";
import { Tab, Tabs } from "react-bootstrap";
import PendingApprovals from "../../components/pending/PendingApprovals";
import PendingRejections from "../../components/pending/PendingRejections";
function Pending() {
  const {
    pendingNurses,
    isFetchingPendingNurses,
    selectedNurse,
    isApprovingNurse,
    isDisapprovingNurse,
  } = useSelector(({ Nurses }) => Nurses);
  const dispatch = useDispatch();
  const alert = useAlert();
  const history = useHistory();
  const handleSelect = (key) => {
    if (key === "plans") {
      // dispatch(fetchLessonStudents());
    } else if (key === "subscribers") {
      // dispatch(fetchPhysicalStudents());
    }
  };
  useEffect(() => {
    dispatch(getPendingNurses());
  }, []);
  useEffect(() => {
    return dispatch(onSetSelectedNurse());
  }, []);
  return (
    <div className="md:pt-20 pb-6 mx-3.5 sm:mx-10 space-y-6 requests">
      <div>
        <h5 className="text-xl leading-10 text-darkblue font-bold">
          Pending Approvals
        </h5>
      </div>

      <Tabs
        defaultActiveKey="approvals"
        id="uncontrolled-tab-example"
        // onSelect={handleSelect}
        className="mb-3 text-sm bg-white "
      >
        <Tab
          className=""
          eventKey="approvals"
          title="Pending Approvals"
          onEnter={() => {
            dispatch(onSetSelectedNurse());
            dispatch(getPendingNurses());
          }}
        >
          <PendingApprovals />
        </Tab>
        <Tab
          className=""
          eventKey="rejections"
          title="Rejections"
          onEnter={() => {
            dispatch(onSetSelectedNurse());
            dispatch(getRejectedNurses());
          }}
        >
          <PendingRejections />
        </Tab>
      </Tabs>
    </div>
  );
}

export default Pending;
