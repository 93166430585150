import actionTypes from "../actions/types";

const initialState = {
  pendingPatients: [],
  isFetchingPatients: false,
  isFetchingPendingPatients: false,
  errors: null,
  message: null,
};
export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.getPatients:
      return {
        ...state,
        isFetchingPatients: true,
        errors: null,
      };
    case actionTypes.getPatientsSuccess:
      return {
        ...state,
        patients: action.payload,
        isFetchingPatients: false,
      };
    case actionTypes.getPatientsFail:
      return {
        ...state,
        errors: action.payload,
        isFetchingPatients: false,
      };

    case actionTypes.getPendingPatients:
      return {
        ...state,
        isFetchingPendingPatients: true,
        errors: null,
      };
    case actionTypes.getPendingPatientsSuccess:
      return {
        ...state,
        pendingPatients: action.payload,
        isFetchingPendingPatients: false,
      };
    case actionTypes.getPendingPatientsFail:
      return {
        ...state,
        errors: action.payload,
        isFetchingPendingPatients: false,
      };
    default:
      return state;
  }
};
