const actionTypes = {
  //AUNTHENTICATIONS

  getUser: "GET USER",
  getUserSuccess: "GET USER SUCCESSFUL",
  getUserFail: "GET USER FAILED",

  updateUser: "UPDATE USER",
  updateUserSuccess: "UPDATE USER SUCCESSFUL",
  updateUserFail: "UPDATE USER FAILED",

  LogUserIn: "LOG USER IN",
  LogUserInSuccess: "LOG USER IN SUCCESSFUL",
  LogUserInFail: "LOG USER IN FAILED",

  LogUserOut: "LOG USER OUT",

  getFirebaseUser: "GET FIREBASE USER",
  getFirebaseUserSuccess: "GET FIREBASE USER SUCCESSFUL",
  getFirebaseUserFail: "GET FIREBASE USER FAILED",
  //Profile
  createProfile: "CREATE PROFILE",
  createProfileSuccess: "CREATE PROFILE SUCCESSFUL",
  createProfileFail: "CREATE PROFILE FAILED",

  getProfile: "GET PROFILE",
  getProfileSuccess: "GET PROFILE SUCCESSFUL",
  getProfileFail: "GET PROFILE FAILED",

  updateProfile: "UPDATE PROFILE",
  updateProfileSuccess: "UPDATE PROFILE SUCCESSFUL",
  updateProfileFail: "UPDATE PROFILE FAILED",
  //Availability
  createAvailability: "CREATE AVAILABILITY",
  createAvailabilitySuccess: "CREATE AVAILABILITY SUCCESSFUL",
  createAvailabilityFail: "CREATE AVAILABILITY FAILED",

  getAvailability: "GET AVAILABILITY",
  getAvailabilitySuccess: "GET AVAILABILITY SUCCESSFUL",
  getAvailabilityFail: "GET AVAILABILITY FAILED",

  updateAvailability: "UPDATE AVAILABILITY",
  updateAvailabilitySuccess: "UPDATE AVAILABILITY SUCCESSFUL",
  updateAvailabilityFail: "UPDATE AVAILABILITYE FAILED",
  //Subscriptions
  createSubscription: "CREATE SUBSCRIPTIONS",
  createSubscriptionSuccess: "CREATE SUBSCRIPTIONS SUCCESSFUL",
  createSubscriptionFail: "CREATE SUBSCRIPTIONS FAILED",

  getSubscriptions: "GET SUBSCRIPTIONS",
  getSubscriptionsSuccess: "GET SUBSCRIPTIONS SUCCESSFUL",
  getSubscriptionsFail: "GET SUBSCRIPTIONS FAILED",

  updateSubscription: "UPDATE SUBSCRIPTIONS",
  updateSubscriptionSuccess: "UPDATE SUBSCRIPTIONS SUCCESSFUL",
  updateSubscriptionFail: "UPDATE SUBSCRIPTIONS FAILED",

  deleteSubscription: "DELETE SUBSCRIPTIONS",
  deleteSubscriptionSuccess: "DELETE SUBSCRIPTIONS SUCCESSFUL",
  deleteSubscriptionFail: "DELETE SUBSCRIPTIONS FAILED",

  setSelectedSubscription: "SET SELECTED SUBSCRIPTIONS",
  //Charts
  getCharts: "GET CHARTS USER",
  getChartsSuccess: "GET CHARTS SUCCESSFUL",
  getChartsFail: "GET CHARTS FAILED",

  getNewChartNurses: "GET NEW CHARTS NURSES",
  getNewChartNursesSuccess: "GET NEW CHARTS NURSES SUCCESSFUL",
  getNewChartNursesFail: "GET NEW CHARTS NURSES FAILED",

  setSelectedNewChartNurse: "SET SELECTED NEW CHARTS NURSE",

  sendChart: "SEND CHART USER",
  sendChartSuccess: "SEND CHART SUCCESSFUL",
  sendChartFail: "SEND CHART FAILED",

  getUsersList: "GET USERS LIST",
  getUsersListSuccess: "GET USERS LIST SUCCESSFUL",
  getUsersListFail: "GET USERS LIST FAILED",

  searchUsersByName: "SEARCH USERS BY NAME",
  searchUsersByNameSuccess: "SEARCH USERS BY NAME SUCCESSFUL",
  searchUsersByNameFail: "SEARCH USERS BY NAME FAILED",

  getLatestMessages: "GET LATEST MESSAGES",
  getLatestMessagesSuccess: "GET LATEST MESSAGES SUCCESSFUL",
  getLatestMessagesFail: "GET LATEST MESSAGES FAILED",

  getTotalNewChart: "GET TOTAL NEW CHAT",
  getTotalNewChartSuccess: "GET TOTAL NEW CHAT SUCCESSFUL",
  getTotalNewChartFail: "GET TOTAL NEW CHAT FAILED",

  getTotalUserUnreadMessages: "GET TOTAL USER UNREAD MESSAGES",
  getTotalUserUnreadMessagesSuccess:
    "GET TOTAL USER UNREAD MESSAGES SUCCESSFUL",
  getTotalUserUnreadMessagesFail: "GET TOTAL USER UNREAD MESSAGES FAILED",

  markChartRead: "MARK CHART READ",
  markChartReadSuccess: "MARK CHART READ SUCCESSFUL",
  markChartReadFail: "MARK CHART READ FAILED",

  markMessagesRead: "MARK MESSAGES READ",
  markMessagesReadSuccess: "MARK MESSAGES READ SUCCESSFUL",
  markMessagesReadFail: "MARK MESSAGES READ FAILED",

  setOpenCharts: "SET OPEN CHARTS",
  setSelectedChart: "SET SELECTED CHART",
  setSelectedUser: "SET SELECTED USER",
  //Report
  getReport: "GET REPORT",
  getReportSuccess: "GET REPORT SUCCESSFUL",
  getReportFail: "GET REPORT FAILED",

  sendReport: "SEND REPORT",
  sendReportSuccess: "SEND REPORT SUCCESSFUL",
  sendReportFail: "SEND REPORT FAILED",

  markReportRead: "MARK REPORT READ",
  markReportReadSuccess: "MARK REPORT READ SUCCESSFUL",
  markReportReadFail: "MARK REPORT READ FAILED",
  setNewReportSum: "SET NEW REPORT SUM",
  setSelectedReport: "SET SELECTED REPORT",

  //Patients
  getPatients: "GET PATIENTS",
  getPatientsSuccess: "GET PATIENTS SUCCESSFUL",
  getPatientsFail: "GET PATIENTS FAILED",

  getPendingPatients: "GET PENDING PATIENTS",
  getPendingPatientsSuccess: "GET PENDING PATIENTS SUCCESSFUL",
  getPendingPatientsFail: "GET PENDING PATIENTS FAILED",
  //INCOME
  getIncomes: "GET INCOMES",
  getIncomesSuccess: "GET INCOMES SUCCESSFUL",
  getIncomesFail: "GET INCOMES FAILED",

  filterIncomesByDate: "GET INCOMES BY DATE",
  filterIncomesByDateSuccess: "GET INCOMES BY DATE SUCCESSFUL",
  filterIncomesByDateFail: "GET INCOMES BY DATE FAILED",

  getIncomeSum: "GET INCOME SUM",
  //Patients
  //Nurses
  getNurses: "GET NURSES",
  getNursesSuccess: "GET NURSES SUCCESSFUL",
  getNursesFail: "GET NURSES FAILED",

  getPendingNurses: "GET PENDING NURSES",
  getPendingNursesSuccess: "GET PENDING NURSES SUCCESSFUL",
  getPendingNursesFail: "GET PENDING NURSES FAILED",

  getRejectedNurses: "GET REJECTED NURSES",
  getRejectedNursesSuccess: "GET REJECTED NURSES SUCCESS",
  getRejectedNursesFail: "GET REJECTED NURSES FAIL",

  getBlockedNurses: "GET BLOCKED NURSES",
  getBlockedNursesSuccess: "GET BLOCKED NURSES SUCCESSFUL",
  getBlockedNursesFail: "GET BLOCKED NURSES FAILED",

  setSelectedNurse: "SET SELECTED NURSE",

  approveNurse: "APPROVE NURSE",
  approveNurseSuccess: "APPROVE NURSE SUCCESSFUL",
  approveNurseFail: "APPROVE NURSE FAILED",

  disapproveNurse: "APPROVE NURSE",
  disapproveNurseSuccess: "APPROVE NURSE SUCCESSFUL",
  disapproveNurseFail: "APPROVE NURSE FAILED",

  blockNurse: "BLOCK NURSE",
  blockNurseSuccess: "BLOCK NURSE SUCCESSFUL",
  blockNurseFail: "BLOCK NURSE FAILED",

  overrideNurseStatus: "OVERRIDE NURSE STATUS",
  overrideNurseStatusSuccess: "OVERRIDE NURSE STATUS SUCCESSFUL",
  overrideNurseStatusFail: "OVERRIDE NURSE STATUS FAILED",

  deleteNurse: "APPROVE NURSE",
  deleteNurseSuccess: "APPROVE NURSE SUCCESSFUL",
  deleteNurseFail: "APPROVE NURSE FAILED",
  //Statistics
  getGeneralStatistics: "GET GENERAL STATISTICS",
  getGeneralStatisticsSuccess: "GET GENERAL STATISTICS SUCCESSFUL",
  getGeneralStatisticsFail: "GET GENERAL STATISTICS FAILED",

  getIncomesStatistics: "GET INCOME STATISTICS",
  getIncomesStatisticsSuccess: "GET INCOME STATISTICS SUCCESSFUL",
  getIncomesStatisticsFail: "GET INCOME STATISTICS FAILED",

  getIncomesStatisticsData: "GET INCOME STATISTICS DATA",
  getIncomesStatisticsSuccessData: "GET INCOME STATISTICS SUCCESSFUL DATA",
  getIncomesStatisticsFailData: "GET INCOME STATISTICS FAILED DATA",

  getPatientsStatistics: "GET PATIENTS STATISTICS",
  getPatientsStatisticsSuccess: "GET PATIENTS STATISTICS SUCCESSFUL",
  getPatientsStatisticsFail: "GET PATIENTS STATISTICS FAILED",

  //Messages
  getMessages: "GET MESSAGES",
  getMessagesSuccess: "GET MESSAGES SUCCESSFUL",
  getMessagesFail: "GET MESSAGES FAILED",

  sendMessage: "SEND MESSAGE",
  sendMessageSuccess: "SEND MESSAGE SUCCESSFUL",
  sendMessageFail: "SEND MESSAGE FAILED",
  //Styles
  setShowSidebar: "SET SHOW SIDEBAR",
};

export default actionTypes;
