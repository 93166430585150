import { fetchReportsRequest, markReportReadRequest } from "../../api";
import AlertMessage from "../../components/alert/Alert";
import actionTypes from "./types";
import html2pdf from "html2pdf.js";
export const answerReport = (data, alert) => {
  return (dispatch) => {};
};
export const getReport = (nameSearchText, dateSearch) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.getReport });
    fetchReportsRequest(nameSearchText ?? "", dateSearch ?? "")
      .then((res) => {
        if (res && res.data) {
          dispatch({
            type: actionTypes.getReportSuccess,
            payload: res.data,
          });
          dispatch(onSetSelectedReport(res.data[0], res?.data));
        }
      })
      .catch((err) => {
        dispatch({ type: actionTypes.getReportFail, payload: err });
      });
  };
};

export const onSetSelectedReport = (report, reports) => {
  return (dispatch, getState) => {
    var reportsList = reports || getState().Reports.reports;
    dispatch({ type: actionTypes.setSelectedReport, payload: report });
    dispatch(markReportRead(report?.id, reports));
  };
};
export const markReportRead = (report_id, reports) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.markReportRead });
    markReportReadRequest(report_id)
      .then((res) => {
        dispatch({ type: actionTypes.markReportReadSuccess, payload: res });

        if (reports?.length !== 0) {
          var sum = 0;
          var newReportSum = reports?.reduce((acc, data) => {
            if (data?.flag === "0") {
              acc = sum + 1;
            }

            return acc;
          }, "");
          dispatch({
            type: actionTypes.setNewReportSum,
            payload: newReportSum,
          });
        }
      })
      .catch((err) => {
        dispatch({ type: actionTypes.markReportReadFail, payload: err });
      });
  };
};
export const generatePdfReport = () => {
  return (dispatch) => {
    var element = document.getElementById("report");
    var clonedElement = element.cloneNode(true);
    // change display of cloned element

    clonedElement.style.display = "block";
    var opt = {
      margin: 1,
      filename: "invoice.pdf",
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "pt", format: "a4", orientation: "portrait" },
    };

    html2pdf().from(clonedElement).set(opt).save();
    clonedElement.remove();
  };
};
