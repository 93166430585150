import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Shimmers from "../../components/loaders/Shimmers";
import NurseDoc from "../../components/nurse/NurseDoc";
import NurseProfile from "../../components/nurse/NurseProfile";
import PendingNursesList from "../../components/pending/nurses/PendingNursesList";
import {
  approveNurse,
  getBlockedNurses,
  onSetSelectedNurse,
} from "../../store/actions";

function BlockedNurses() {
  const {
    blockedNurses,
    isFetchingBlockedNurses,
    selectedNurse,
    isApprovingNurse,
    isDisapprovingNurse,
  } = useSelector(({ Nurses }) => Nurses);
  const [unBlockNurseAlert, setUnblockNurseAlert] = useState(false);
  const dispatch = useDispatch();
  const alert = useAlert();
  const history = useHistory();
  const handleClose = () => {
    setUnblockNurseAlert(false);
  };
  useEffect(() => {
    dispatch(onSetSelectedNurse());
    dispatch(getBlockedNurses());
  }, []);
  return (
    <div className="md:pt-20 pb-6 mx-3.5 sm:mx-10 grid grid-cols-1 lg:grid-cols-3 lg:gap-x-4 gap-y-4">
      <div>
        <PendingNursesList
          nurses={blockedNurses}
          isFetching={isFetchingBlockedNurses}
          blocked={true}
        />
      </div>
      {isFetchingBlockedNurses ? (
        <div className="col-span-2 p-4 bg-white rounded-xl space-y-6">
          <div className="rounded-xl overflow-hidden">
            <Shimmers width="100%" height="70vh" />
          </div>
        </div>
      ) : selectedNurse ? (
        <div className="col-span-2 p-8 bg-white rounded-xl space-y-6 lg:min-h-carditem">
          <NurseProfile blocked={true} />
          <div>
            <h5 className="text-xl font-bold text-darkblue">Biography</h5>
            <p className="bg-paleblue p-4 rounded-2xl text-sm text-gray-500 overflow-auto">
              {selectedNurse?.nurse_profile?.biography}
            </p>
          </div>
          <NurseDoc />
          <div className=" flex justify-center space-x-4 my-4">
            <button
              onClick={() => setUnblockNurseAlert(true)}
              className="bg-lightgreen hover:opacity-70 px-10 py-1.5 text-white text-sm rounded-xl"
            >
              {/* {isApprovingNurse ? "Approving ..." : "Approve Account"} */}
              Unblock
            </button>

            {/* <button className="bg-lightgreen hover:opacity-70 py-1 px-1.5 text-white text-sm rounded-xl">
      <AiTwotoneMessage />
    </button> */}
          </div>
        </div>
      ) : null}
      <Modal show={unBlockNurseAlert} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            <div className="text-base font-semibold text-darkblue">
              Unblock Nurse "{selectedNurse?.full_names}"
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-sm px-3">
          Do you really want to unblock this nurses?
        </Modal.Body>
        <Modal.Footer className="flex justify-between">
          <button
            className="bg-red-500 rounded px-3 py-1 text-white text-sm hover:bg-red-400 cursor-pointer"
            onClick={handleClose}
          >
            No, Cancel
          </button>
          <button
            className="bg-gray-500 rounded px-3 py-1 text-white text-sm hover:bg-gray-400 cursor-pointer"
            onClick={() => {
              dispatch(approveNurse(selectedNurse, alert, history));
              // handleClose();
            }}
          >
            {isApprovingNurse ? "Unblocking ..." : "Yes, Unblock"}
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default BlockedNurses;
