import React, { useEffect } from "react";
import userImg from "../../assets/images/nurse 2.png";
import { FaSlidersH } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { getReport } from "../../store/actions";
import Reports from "../../pages/reports";
import Shimmers from "../loaders/Shimmers";
import moment from "moment";
import { GetValidImage } from "../../utils/image";
import { useHistory } from "react-router-dom";
function LatestReports() {
  const { reports, isFetchingReport } = useSelector(({ Reports }) => Reports);
  const dispatch = useDispatch();
  const history = useHistory();
  useEffect(() => {
    dispatch(getReport());
  }, []);
  return (
    <div>
      <div className="flex justify-start items-center	py-2	 ">
        <h2 className=" flex font-black text-darkblue text-xl ">Reports</h2>
        {/* <FaSlidersH className=" flex text-darkblue" /> */}
      </div>
      {isFetchingReport ? (
        <div className="w-100">
          <Shimmers width="100%" height="60px" />
          <Shimmers width="100%" height="60px" />
        </div>
      ) : (
        <div>
          {reports?.length > 0 &&
            reports.slice(0, 3).map((report, index) => (
              <div
                key={index}
                className="p-2 flex space-x-3 bg-white rounded-2 mb-2"
              >
                <div className="relative  flex items-center w-14 h-14">
                  <img
                    src={GetValidImage(report?.nurse?.avatar)}
                    alt="Profile"
                    className=""
                  />
                </div>
                <div className="flex-1">
                  <p className="text-base text-darkblue font-bold">
                    {report?.nurse?.full_names}
                  </p>
                  <span className="text-xs text-gray-700">
                    {" "}
                    {moment(report?.created_at).format(
                      "MMMM Do YYYY, h:mm:ss a"
                    )}
                  </span>
                  <button
                    onClick={() => history.push("/dashboard/reports")}
                    className="text-sm text-lightgreen hover:opacity-70 block w-100 text-end"
                  >
                    Read Report
                  </button>
                </div>
              </div>
            ))}
        </div>
      )}
      {reports?.length === 0 && !isFetchingReport && (
        <p className="text-sm text-gray-800 "> no reports for now.</p>
      )}
    </div>
  );
}

export default LatestReports;
