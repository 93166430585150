import {
  fetchPatientsRequest,
  fetchPendingPatientsRequestsForNurse,
} from "../../api";
import actionTypes from "./types";
import html2pdf from "html2pdf.js";
export const getPatients = (searchTxt, selectedStartDate, selectedEndDate) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.getPatients });

    fetchPatientsRequest(
      searchTxt ?? null,
      selectedStartDate ?? null,
      selectedEndDate ?? null
    )
      .then((res) => {
        if (res && res.data) {
          dispatch({ type: actionTypes.getPatientsSuccess, payload: res.data });
        }
      })
      .catch((err) =>
        dispatch({ type: actionTypes.getPatientsFail, payload: err })
      );
  };
};

export const getPendingPatients = () => {
  return (dispatch) => {
    dispatch({ type: actionTypes.getPendingPatients });
    fetchPendingPatientsRequestsForNurse()
      .then((res) => {
        if (res && res.data) {
          dispatch({
            type: actionTypes.getPendingPatientsSuccess,
            payload: res.data,
          });
        }
      })
      .catch((err) =>
        dispatch({ type: actionTypes.getPendingPatientsFail, payload: err })
      );
  };
};
export const generatePdfPatients = () => {
  return (dispatch) => {
    var element = document.getElementById("patient-to-print");
    var clonedElement = element.cloneNode(true);
    clonedElement.style.display = "block";
    var opt = {
      margin: 1,
      filename: "Patients.pdf",
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "pt", format: "a4", orientation: "portrait" },
    };

    html2pdf().from(clonedElement).set(opt).save();
    clonedElement.remove();
  };
};
