import React, { useEffect, useRef, useState } from "react";
import { BsDot } from "react-icons/bs";
import { useSelector } from "react-redux";
import userImg from "../../../assets/images/user_img.png";
import ConversationForm from "./ConversationForm";
import ReceivedConversation from "./ReceivedConversation";
import SentConversation from "./SentConversation";
import Moment from "react-moment";
function Conversation({ currentUser }) {
  const { charts, selectedChart, selectedUser } = useSelector(
    ({ Charts }) => Charts
  );

  const scrollRef = useRef();
  const getTime = (chart) => {
    let dateObj = new Date(chart?.createdAt?.seconds);
    return dateObj;
  };
  useEffect(() => {
    scrollRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [selectedChart]);

  return (
    <div className="rounded-3xl  overflow-hidden relative">
      <div className="bg-white flex  items-center p-6 h-100">
        <img
          src={selectedUser?.avatar || userImg}
          alt="Profile"
          className="w-14 h-14 rounded-circle"
        />
        <div className="mx-3">
          <h5 className="font-bold text-darkblue text-base">
            {selectedUser?.name}
          </h5>
          <p className="text-xs text-gray-500">{selectedUser?.role}</p>
        </div>
      </div>
      <div className="bg-white mt-2 py-10 px-3.5 sm:px-16 h-96 lg:h-chatitem lg:sticky lg:top-0  overflow-auto">
        <ul className="space-y-6 mb-16">
          {selectedChart?.length > 0 &&
            selectedChart.map((chart, index) =>
              chart?.from === currentUser?.uid ? (
                <div key={index} className="space-y-6 " ref={scrollRef}>
                  <div className="flex justify-center">
                    {/* <Moment
                      className="text-sm px-5 py-1.5 bg-paleblue rounded-3xl text-darkblue font-bold"
                      format="YYYY/MM/DD"
                      fromNow
                    >
                      {chart.createdAt.toDate()}
                    </Moment> */}
                  </div>
                  <SentConversation chart={chart} />

                  <div></div>
                </div>
              ) : (
                <div key={index} className="space-y-6">
                  <div className="flex justify-center">
                    {/* {chart?.createdAt?.seconds && (
                      <Moment
                        className="text-sm px-5 py-1.5 bg-paleblue rounded-3xl text-darkblue font-bold"
                        format="YYYY/MM/DD"
                        fromNow
                      >
                        {chart.createdAt.toDate()}
                      </Moment>
                    )} */}
                  </div>
                  <ReceivedConversation chart={chart} />

                  <div></div>
                </div>
              )
            )}
        </ul>
      </div>
      {selectedUser && <ConversationForm currentUser={currentUser} />}
    </div>
  );
}

export default Conversation;
