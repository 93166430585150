import actionTypes from "../actions/types";

const initialState = {
  // incomes: [
  //   {
  //     id: 1,
  //     payer_name: "Johnathan Doe",
  //     nurse_name: "Uwimana Jeanne",
  //     subscription: "1 Month",
  //     paymentMode: "MTN Mobile Money",
  //     amount: "100,000",
  //   },
  //   {
  //     id: 2,
  //     payer_name: "Uwimana Princess",
  //     nurse_name: "Uwimana Jeanne",
  //     subscription: "3 days",
  //     paymentMode: "MTN Mobile Money",
  //     amount: "100,000",
  //   },
  //   {
  //     id: 3,
  //     payer_name: "Ishimwe David",
  //     nurse_name: "Uwimana Jeanne",
  //     subscription: "1 Week",
  //     paymentMode: "Visa Card",
  //     amount: "100,000",
  //   },
  //   {
  //     id: 4,
  //     payer_name: "Uwimana Princess",
  //     nurse_name: "Uwimana Jeanne",
  //     subscription: "3 days",
  //     paymentMode: "MTN Mobile Money",
  //     amount: "100,000",
  //   },
  //   {
  //     id: 5,
  //     payer_name: "Ishimwe David",
  //     nurse_name: "Uwimana Jeanne",
  //     subscription: "1 Week",
  //     paymentMode: "Visa Card",
  //     amount: "100,000",
  //   },
  //   {
  //     id: 6,
  //     payer_name: "Uwimana Princess",
  //     nurse_name: "Uwimana Jeanne",
  //     subscription: "3 days",
  //     paymentMode: "MTN Mobile Money",
  //     amount: "100,000",
  //   },
  //   {
  //     id: 7,
  //     payer_name: "Ishimwe David",
  //     nurse_name: "Uwimana Jeanne",
  //     subscription: "1 Week",
  //     paymentMode: "Visa Card",
  //     amount: "100,000",
  //   },
  //   {
  //     id: 8,
  //     payer_name: "Uwimana Princess",
  //     nurse_name: "Uwimana Jeanne",
  //     subscription: "3 days",
  //     paymentMode: "MTN Mobile Money",
  //     amount: "100,000",
  //   },
  //   {
  //     id: 9,
  //     payer_name: "Ishimwe David",
  //     nurse_name: "Uwimana Jeanne",
  //     subscription: "1 Week",
  //     paymentMode: "Visa Card",
  //     amount: "100,000",
  //   },
  // ],
  incomes: [],
  incomesSum: null,
  isFetchingIncomes: false,
  isFilteringIncomes: false,
  errors: null,
  message: null,
};
export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.getIncomes:
      return {
        ...state,
        isFetchingIncomes: true,
        errors: null,
      };
    case actionTypes.getIncomesSuccess:
      return {
        ...state,
        incomes: action.payload,
        isFetchingIncomes: false,
      };
    case actionTypes.getIncomesFail:
      return {
        ...state,
        errors: action.payload,
        isFetchingIncomes: false,
      };

    case actionTypes.getIncomeSum:
      return {
        ...state,
        incomesSum: action.payload,
      };
    case actionTypes.getIncomes:
      return {
        ...state,
        isFilteringIncomes: true,
        errors: null,
      };
    case actionTypes.getIncomesSuccess:
      return {
        ...state,
        incomes: action.payload,
        isFilteringIncomes: false,
      };
    case actionTypes.getIncomesFail:
      return {
        ...state,
        errors: action.payload,
        isFilteringIncomes: false,
      };

    default:
      return state;
  }
};
