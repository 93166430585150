import actionTypes from "./types";
import {
  getGeneralStatisticsDataRequest,
  getIncomesStatisticsRequest,
  getPatientsStatisticsRequest,
} from "../../api";
import axios from "axios";
import moment from "moment";
export const getDashboardStatisticsData = () => {
  return (dispatch) => {
    dispatch({ type: actionTypes.getIncomesStatisticsData });
    getGeneralStatisticsDataRequest()
      .then((res) => {
        dispatch({
          type: actionTypes.getIncomesStatisticsSuccessData,
          payload: res.data,
        });
      })
      .catch((error) => {
        const err = error.message;
        dispatch({
          type: actionTypes.getIncomesStatisticsFailData,
          payload: err,
        });
      });
  };
};

export const getGeneralStatistics = () => {
  return (dispatch) => {
    dispatch({ type: actionTypes.getGeneralStatistics });

    getGeneralStatisticsDataRequest()
      .then((res) => {
        if (res && res.data) {
          dispatch({
            type: actionTypes.getGeneralStatisticsSuccess,
            payload: res.data,
          });
        }
      })
      .catch((err) =>
        dispatch({ type: actionTypes.getGeneralStatisticsFail, payload: err })
      );
  };
};
export const getIncomesStatistics = (dateSelected) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.getIncomesStatistics });
    var today = new Date();
    getIncomesStatisticsRequest(
      dateSelected || moment(today).format("YYYY-MM-DD")
    )
      .then((res) => {
        if (res && res.data) {
          var incomesStatistics = [];

          const labels = res.data.reduce((acc, { date }) => {
            acc += " " + date;
            return acc.trim().split(" ").join(",");
          }, "");
          const incomes = res.data.reduce((acc, { income }) => {
            acc += " " + income;
            return acc.trim().split(" ").join(",");
          }, "");

          incomesStatistics.push({
            labels: labels.split(","),
            incomes: incomes.split(",").map(Number),
          });
          dispatch({
            type: actionTypes.getIncomesStatisticsSuccess,
            payload: incomesStatistics[0] || [],
          });
        }
      })
      .catch((err) =>
        dispatch({ type: actionTypes.getIncomesStatisticsFail, payload: err })
      );
  };
};
export const getPatientsStatistics = (dateSelected) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.getPatientsStatistics });
    var today = new Date();
    getPatientsStatisticsRequest(
      dateSelected || moment(today).format("YYYY-MM-DD")
    )
      .then((res) => {
        if (res && res.data) {
          var patientsStatistics = [];

          const labels = res.data.reduce((acc, { date }) => {
            acc += " " + date;
            return acc.trim().split(" ").join(",");
          }, "");
          const patients = res.data.reduce((acc, { patients }) => {
            acc += " " + patients;
            return acc.trim().split(" ").join(",");
          }, "");
          //patients.split(",").map(i=>Number(i));
          patientsStatistics.push({
            labels: labels.split(","),
            patients: patients.split(",").map(Number),
          });
          dispatch({
            type: actionTypes.getPatientsStatisticsSuccess,
            payload: patientsStatistics[0] || [],
          });
        }
      })
      .catch((err) =>
        dispatch({ type: actionTypes.getPatientsStatisticsFail, payload: err })
      );
  };
};
