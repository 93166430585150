import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getNewChartNurses,
  onSelectedNewChartNurse,
} from "../../store/actions";
import userImg from "../../assets/images/user_img.png";
import Shimmers from "../../components/loaders/Shimmers";
import { GetValidImage } from "../../utils/image";
import { ImSearch } from "react-icons/im";
import debounce from "lodash.debounce";
function NewChatUsersList() {
  const { newChartNurses, isFetchingNewUsers } = useSelector(
    ({ Charts }) => Charts
  );
  const [searchText, setSearchText] = useState(null);
  const dispatch = useDispatch();
  const onChange = (value) => {
    setSearchText(value);
    const search = debounce(sendQuery, 1000);
    if (value && value.length > 0) {
      search(value);
    }
    if (value.length === 0) {
      // api call when length null or 0
      dispatch(getNewChartNurses());
    }
  };
  const sendQuery = async (value) => {
    // add you api  or dispatch action call here
    dispatch(getNewChartNurses(value));
    setSearchText(null);
  };
  useEffect(() => {
    dispatch(getNewChartNurses());
  }, []);
  return (
    <div className="p-10 flex items-center">
      {isFetchingNewUsers ? (
        <div className="overflow-hidden">
          <Shimmers width="400px" height="50px" />
          <Shimmers width="400px" height="50px" />
          <Shimmers width="400px" height="50px" />
        </div>
      ) : (
        <div>
          <div className="flex items-center  bg-white rounded-3xl py-2.5 px-3 space-x-3 mt-2 sm:mt-0">
            <ImSearch className="text-darkblue" />
            <input
              type="text"
              id="search_text"
              name="search_text"
              placeholder="Search chat"
              className="text-sm text-darkblue flex-1"
              onChange={(e) => onChange(e.target.value)}
            />
          </div>
          {newChartNurses?.map((user, index) => (
            <button
              onClick={() => dispatch(onSelectedNewChartNurse(user))}
              className="bg-white flex  items-center p-6"
            >
              <img
                src={user?.avatar ? GetValidImage(user?.avatar) : userImg}
                alt="Profile"
                className="w-14 h-14 rounded-circle"
              />
              <div className="mx-3">
                <h5 className="font-bold text-darkblue text-base">
                  {user?.full_names}
                </h5>
                <p className="text-sm text-gray-500">{user?.role}</p>
              </div>
            </button>
          ))}
          {newChartNurses?.length === 0 ? (
            <p className="text-sm text-gray-700 px-3">no nurses found</p>
          ) : null}
        </div>
      )}
    </div>
  );
}

export default NewChatUsersList;
