import React from "react";
import { Redirect } from "react-router-dom";
import BlankLayout from "../layout/BlankLayout";
import DashboardLayout from "../layout/DashboardLayout";
import Home from "../pages/Home";
import SignIn from "../pages/auth/SignIn";
import Charts from "../pages/charts";
import Reports from "../pages/reports";
import Income from "../pages/Income";
import Nurses from "../pages/Nurses";
import NurseDetails from "../pages/Nurses/NurseDetails";
import Pending from "../pages/Pending";
import Patients from "../pages/Patients";
import {
  requiresAuthentication,
  requiresNoAuthentication,
} from "../store/middleware";
import Pricing from "../pages/Pricing";
import BlockedNurses from "../pages/Nurses/BlockedNurses";

const routes = [
  {
    component: BlankLayout,

    routes: [
      {
        name: "Index",
        path: "/",
        exact: true,
        render: () => <Redirect to="/signin" />,
      },
      {
        name: "Dashboard",
        path: "/dashboard",
        component: requiresAuthentication(DashboardLayout),
        routes: [
          {
            name: "Dashboard Home",
            path: "/dashboard",
            exact: true,
            component: Home,
          },
          {
            name: "Charts page",
            path: "/dashboard/charts",
            exact: true,
            component: Charts,
          },
          {
            name: "Reports page",
            path: "/dashboard/reports",
            exact: true,
            component: Reports,
          },
          {
            name: "Income page",
            path: "/dashboard/income",
            exact: true,
            component: Income,
          },
          {
            name: "Nurses ",
            path: "/dashboard/nurses",
            exact: true,
            component: Nurses,
          },
          {
            name: "Nurses Details ",
            path: "/dashboard/nurses/:nurseId",
            exact: true,
            component: NurseDetails,
          },
          {
            name: "Pending Profile ",
            path: "/dashboard/pending",
            exact: true,
            component: Pending,
          },
          {
            name: "Brocked Nurses ",
            path: "/dashboard/blocked",
            exact: true,
            component: BlockedNurses,
          },
          {
            name: "Patients Profile ",
            path: "/dashboard/patients",
            exact: true,
            component: Patients,
          },
          {
            name: "Pricing ",
            path: "/dashboard/pricing",
            exact: true,
            component: Pricing,
          },
        ],
      },

      {
        name: "Sign in page",
        path: "/signin",
        exact: true,
        component: requiresNoAuthentication(SignIn),
      },
    ],
  },
];
export default routes;
